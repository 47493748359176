<template>
	<div class="video-insights" v-if="isPaid(get('video')) || isFree(get('video'))">
		<v-row class="mt-n2">
			<v-col class="d-flex align-center">

				<v-icon
					class="mr-2 "
					@click="like(true, get('video').id)"
				>
					mdi-thumb-up-outline
				</v-icon>
				<span class="subtitle-2">{{get('video').likes}}</span>

				<v-icon
					class="mr-2 ml-6"
					@click="like(false, get('video').id)"
				>
					mdi-thumb-down-outline
				</v-icon>
				<span class="subtitle-2  ">{{get('video').dislikes}}</span>
			<v-btn class="ml-1" text @click="openModal('socialSharing')"><v-icon>mdi-share</v-icon>share</v-btn>

			</v-col>
		</v-row>

	</div>
</template>

<script>

import { mapGetters } from 'vuex';
import APIService from '@/services/api.service';
import vActions from '@/components/player/videoInsightsActions/vActions';

export default {
  name: 'vActions',
	extends: vActions,

	data(){
		return{
			videoFav: false,
			vFavText: false,
			vFavTextUnset: false,
			vLikeText: false,
			vDislikeText: false
		}
	},

	computed:{...mapGetters(["get"])},

	components: {
		socialSharing: () => import('@/components/modal/socialSharing')
	},

	methods: {

		shareLink(item){
			return `${window.location.protocol}//${window.location.host}/videos/${item.id}`;
		},

		// Actions
		setVidFav(videoId){
			this.videoFav = !this.videoFav
			this.$store.dispatch('patch',
      {
      	endpoint: `/api/v1/users/${this.$store.state.main.user.result.id}`,
      	event: {favorits: [videoId]},
				message: false
			})
			.then(() => {
				this.$store.dispatch('fetch', {endpoint: `/api/v1/users/me`, state: 'user'})
      })
			APIService.setResponse({message: 'Video in Favoriten gespeichert', color: 'green'})
			// console.log("Save Video als Fav -> heroVideoInsights");
		},

		deleteFavVid(videoId){
			this.$store.dispatch('delete',{
				endpoint: `/api/v1/favorits/${videoId}`,
				message: false
			}).then(() => {
				this.$store.dispatch('fetch', {endpoint: `/api/v1/users/me`, state: 'user'})
				APIService.setResponse({message: 'Video aus Favoriten entfernt', color: 'green'})
			})
		},

		isFav(item){
			let fav = false;
			const favorits = this.$store.state.main.user.result.favorits;
			favorits.forEach((favorit) => {
				favorit.videos.forEach((video) => {
					if ( video.id === item.id ) {
						fav = true;
					}
				});
			});
			return fav;
		},

		like(value, id){
			this.$store.dispatch('post',
        {
        	endpoint: `/api/v1/reviews`,
        	event: {video: id, like: value},
					state: 'reviews',
					message: false
        })
				.then(() => {
					this.$store.dispatch('fetch', {endpoint: `/api/v1/videos/${this.$store.state.main.video.result.id}`, state: 'video'})
		    })
				.catch(() => {
					APIService.setResponse({message: "Es wurde schon eine Bewertung für diese Video abgegeben", color: "red"})
		     })
		},

		isPaid(item) {
			let paid = false;
			if(this.$store.state.main.user.result){
				if(item.subscription){
					const subVideo = this.$store.state.main.user.result.subscriptions
					if (subVideo.length == 0) {
						paid = false;
					}
				}
				else {
					const paidOrders = this.$store.state.main.user.result.orders
					paidOrders.forEach((order) => {
						order.videos.forEach((video) => {
							if ( video.id === item.id ) {
								paid = true;
							}
						});
					});
				}
			}
			return paid
		},

		isFree(item){
			let free = false;
			if ( item.price == 0 && item.subscription == false ) {
				free = true;
			}
			return free;
		},

		openModal(name) {
			this.$store.dispatch("modal/open", name)
		},

	}

}
</script>
