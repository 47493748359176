import Vue from 'vue';
import Vuex from 'vuex';
import { auth } from  './auth'
import modal from  './modal'
import response from  './response'
import main from  './main'
import rightSiteDrawer from  './rightSiteDrawer'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    main,
    rightSiteDrawer,
    auth,
    modal,
    response
  }
});
