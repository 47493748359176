<template>
	<modal name="newPlan">
		<!-- Headline -->
		<div class="pa-6">
			<v-card-title>
				<span class="headline">Neuen Plan erstellen</span><br>
			</v-card-title>
			<v-card-text>
				<p>Wir empfehlen nicht mehr als drei Pläne zu erstellen.</p>
			</v-card-text>
			
			<v-form 
				v-on:submit.prevent 
				@submit="createPlan($event)"
				v-model="valid"
			>
				<!-- Name des Plans -->
				<v-text-field
					label="Vergib einen Namen"
					name="name"
					v-model="name"
					placeholder="Basis"
					prepend-inner-icon="mdi-cash-clock"						
					required
					counter="30"
					class="px-4"
					filled
					clearable
					:rules="planNameRules"
				></v-text-field>

				<!-- Beschreibung des Plans -->
				<v-textarea
					label="Inhalte"
					name="description"
					v-model="description"
					placeholder="Kurze Beschreibung"
					required
					counter="500"
					class="px-4"
					filled
					clearable
					:rules="planDescriptionRules"
				></v-textarea>


				<span class="title my-2 mx-4">Preisdetails</span>
				<!-- Preis der Gallery -->
				<v-text-field
					label="Vergib einen Preis"
					name="price"
					v-model="price"
					prepend-inner-icon="mdi-currency-eur"
					placeholder="5,99"
					type="number"
					min="0"
					:rules="priceRules"
					inputMode="decimal"
					filled
					clearable
					class="px-4"
					required
					></v-text-field>
					
				
				<!-- Laufzeit == Zahlweise des Plans -->
				
				<v-select
					class="px-4"
					v-model="paymentCircle.id"
					:items="paymentCircle"
					label="Zahlungslauf / Laufzeit"
					prepend-inner-icon="mdi-cash-clock"
					item-text="id"
					item-value="id"
					name="period"
					filled
					required
				></v-select>

				<v-select
					class="px-4"
					v-model="trialVariation.id"
					:items="trialVariation"
					label="Testzeitraum"
					prepend-inner-icon="mdi-test-tube"
					item-text="id"
					item-value="id"
					name="trial"
					filled
					required
				></v-select>
				
				
				<!-- <v-row class="mx-2">
					<p class="body-1 pt-5 pr-3">Testzeitraum:</p>
					
					<v-spacer></v-spacer>
					<v-switch
						v-model="trialSelection"
						@change="clearTrialSelection"
						></v-switch>
						
						<v-radio-group
							row
							mandatory
							v-model="trial"
							v-if="trialSelection"
							class="ml-4"
						>
						<v-radio
							label="7 Tage"
							value="Woche"
						></v-radio>

						<v-radio
							label="1 Monat"
							value="Monat"
						></v-radio>

					</v-radio-group>

				</v-row> -->
				<span class="title my-2 mx-4">Sichtbarkeit</span>
				<div class="mt-n4 px-4 d-flex justify-space-between align-center">
					<div>
						<span class="body-1">Plan aktivieren?</span>
						<v-icon 
							v-if="activePlan == true"	
							class="green--text pl-2"
							size="18"
						>mdi-check</v-icon>
					</div>
					<div class="d-flex align-center">
						<span class="pr-4 grey--text">nein / ja</span>
						<v-switch
							v-model="activePlan"
						></v-switch>
					</div>
				</div>

				<v-divider class="mb-4 mx-4"></v-divider>
				<v-card-actions class="px-4 d-flex justify-end">
					<v-btn text grey--text class="font-weight-regular" @click="close('newPlan')">abbrechen</v-btn>
					<v-btn color="green darken-1" outlined dark type="submit" :loading="loading" >Erstellen</v-btn>
				</v-card-actions>
			</v-form>
		</div>
	</modal>
</template>

<script>

	export default {
		name: 'newPlan',

		components: {
			Modal: () => import('@/components/modal/modal')
		},

		data(){
			return {
				name: '',
				description: '',
				price: '',
				trial: '',
				activePlan: true,
				trialSelection: false,
				extraSelection: false,
				paymentCircle: ['Monatlich', 'Jährlich'], // Monatlich oder Jährlich,
				trialVariation: ['Kein Testzeitraum', 'Woche', 'Monat'], // Monatlich oder Jährlich,
				planNameRules: [
					v => !!v || 'Bitte Namen vergeben',
					v => (v && v.length <= 30) || 'Der Name darf nicht mehr als 30 Zeichen haben.',
					v => (v && v.length >= 2) || 'Der Name muss mindestens 2 Zeichen haben.'
				],
				planDescriptionRules: [
					v => !!v || 'Bitte eine Beschreibung eingeben',
					v => (v && v.length <= 500) || 'Die Beschreibung darf nicht mehr als 30 Zeichen haben.',
					v => (v && v.length >= 2) || 'Der Name muss mindestens 2 Zeichen haben.'
				],
				priceRules: [
					v => !!v || 'Preis darf nicht leer sein',
					v => !isNaN(parseFloat(v)) && !isNaN(v - 0) || 'Preis darf nur Zahlen und Komma enthalten',
					v => v >= 0 || 'Preis darf nicht negativ sein'
				],
				loading: false,
				valid: false
			}
		},

		methods: {
			currentUser() {
				return this.$store.state.auth.status.loggedIn;
			},

			clearTrialSelection(){
				this.trial = '';
			},

			changePaymentCircle(){
				if (this.paymentCircle == 'Jährlich') {
					this.paymentCircle = 'Monatlich'
				}
				else {
					this.paymentCircle = 'Monatlich'
				}
			},

			createPlan(e){
				this.loading = true;
				this.price.replace(',', '.');
				if(this.trialVariation.id == 'Kein Testzeitraum'){
					this.trialVariation.id = '';
				}
				this.$store.dispatch('post',
					{
						endpoint: '/api/v1/plans',
						state: 'plans',
						event: {
							name: this.name,
							description: this.description,
							price: this.price.replace(',', '.'),
							period: this.paymentCircle.id,
							currency: 'EUR',
							trial: this.trialVariation.id,
							active: this.activePlan,
						}
					}
				)
				.then(() => {
					this.$store.dispatch('fetch', { endpoint: '/api/v1/plans', state: 'plans'})
					this.loading = false
					this.$store.dispatch("modal/close", 'newPlan')
				}).catch((error) => {
					this.loading = false
				})
			},

			close(name) {
				this.$store.dispatch("modal/close", name)
			}
		}
		// Ende Methods
	}
</script>
