import APIService from "./api.service";
import authHeader from './auth-header';
import main from '../store/main';


/**
 * payloadData is the data structure sent to the transcoder 
 * websocketConnection to specify transcoding options.
 * 
 * It contains format, video, audio, and filter options that 
 * configure how the video should be transcoded.
 */
var payloadData = {
    "format": {
        "container": "mp4",
        "clip": false
    },
    "video": {
        "uuid": "",
        "codec": "libx264",
        "preset": "fast",
        "pass": "1",
        "crf": 23,
        "pixel_format": "auto",
        "frame_rate": "auto",
        "speed": "auto",
        "tune": "none",
        "profile": "none",
        "level": "none",
        "faststart": false,
        "size": "source",
        "width": "",
        "height": "",
        "mobile": "true", // leer für false
        "hdready": "true",
        "fullhd": "true",
        "format": "widescreen",
        "aspect": "auto",
        "scaling": "auto",
        "codec_options": "",
        "HLS": "hls",
        "HlsListSize": 10,
        "StrictEnabled": "2",
        "HlsTime": 10,
        "HlsPlaylistType": "vod",
        "hlsKeyPath": "false",
        "hlsKeyInfoFile": "",
        "HlsFlags": "append_list",
        "resolutions": "true",
    },
    "audio": {
        "codec": "aac",
        "channel": "source",
        "quality": "128k",
        "sampleRate": "auto",
        "volume": "100"
    },
    "filter": {
        "deband": false,
        "deshake": false,
        "deflicker": false,
        "dejudder": false,
        "denoise": "none",
        "deinterlace": "none",
        "brightness": "0",
        "contrast": "1",
        "saturation": "0",
        "gamma": "0",
        "acontrast": "33"
    }
};

/**
 * Configuration options for the FFmpeg transcoder.
 * Defines video, audio and filter settings to use for transcoding.
*/
class transcoderService {
    component;
    
    /**
     * Initializes the websocketConnection property to null.
     * This is called when constructing a new instance of the class.
     */
    constructor() {
        this.websocketConnection = null;
    }
  
    /**
     * Starts a WebSocket connection to the transcoder service.
     * Binds the WebSocket events to methods on the component instance.
     *
     * @param {Object} component - The Vue component instance to connect the WebSocket events to
     */
    startWebSocket(component) {
        this.component = component;

        /**
         * Generates a JSON Web Token for uploading media.
         * Uses the authHeader utility to generate a token with the 'upload_token' scope.
        */
        const token = JSON.stringify(authHeader('upload_token'));
        
        let protocol;
        process.env.NODE_ENV === 'production' ?
            protocol = `wss:` :
            protocol = `ws:`


        // this.websocketConnection = new WebSocket(`${protocol}//${main.state.config.result.socket}/ws?token=${token}`);
        this.websocketConnection = new WebSocket(`${protocol}//${main.state.config.result.socket}/ws`);
        // this.websocketConnection = new WebSocket(`ws:localhost:32775/ws`);
        
        this.websocketConnection.onopen = (event) => {
            this.onOpen(event);
        };

        this.websocketConnection.onerror = (event) => {
            this.onError(event);
        };

        this.websocketConnection.onclose = (event) => {
            this.onClose(event);
        };

        this.websocketConnection.onmessage = (event) => {
            component.onWSMessage(event)
        };
    }

    onOpen(event) {
        // console.log("Verbunden", event)
        this.writeToScreen("Transcoder ist bereit", 'blue');
    }
    
    onClose(event) {
        this.websocketConnection.close();
        this.writeToScreen("DISCONNECTED");
        // console.log("DISCONNECTED Reason", event)
    }
    
    // onMessage(event) {
    //     this.component.onWSMessage = event
    // }
    
    onError(event) {
        this.writeToScreen("Adresse des websocketConnection korrekt?");
        // console.log("ERROR:", event)
    }
    
    
    /**
     * Handles submitting the upload response to the websocket server to start encoding.
     * Validates the uploadResponse object and its properties. 
     * Constructs the input and output paths and payload data to send to the websocket server.
     * Sends the encode message to the websocket server if connected.
     */
    onSubmit(uploadResponse) {
        if (!uploadResponse || typeof uploadResponse !== 'object') {
            throw new Error('Invalid uploadResponse');
        }

        if (!uploadResponse.uuid || typeof uploadResponse.uuid !== 'string') {
            throw new Error('Invalid uuid in uploadResponse');
        }

        const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
        if (!uuidRegex.test(uploadResponse.uuid)) {
            throw new Error('Invalid uuid format in uploadResponse');
        }

        if (!uploadResponse.title || typeof uploadResponse.title !== 'string' || uploadResponse.title.length > 100) {
            throw new Error('Invalid title in uploadResponse');
        }

        payloadData.video.uuid = uploadResponse.uuid;

        const input = "/var/www/public/files/video_uploads/" + uploadResponse.uuid + "/original" + uploadResponse.title.match(/\.[0-9a-z]+$/i);
        const output = "";    // Muss nicht mehr angegeben werden
        const payload = JSON.stringify(payloadData, null, 2);   // Alle Optionen, welche im JSON Objekt oberhalt definiert sind

        // this.writeToScreen("SENT: " + JSON.stringify({ type: 'encode', input }));

        if (this.websocketConnection && this.websocketConnection.readyState === WebSocket.OPEN) {
            this.websocketConnection.send(JSON.stringify({
                type: 'encode',
                input,
                output,
                payload,
            }))
        } else {
            console.error("websocketConnection is not open.");
        }
    }

    /**
     * Writes a message to the screen/log with the given color.
     * @param {string} msg - The message to write. 
     * @param {string} color - The color for the message.
     */
    writeToScreen(msg, color) {
        APIService.setResponse({ message: msg, color: color });
    }
}

export default new transcoderService();