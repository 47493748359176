<template>
  <div style="margin: auto;">

    <div
      v-if="step < 5"
      class="text-center mt-5"
    >
      <v-img
        class="mb-4"
        contain
        height="128"
        :src="imageSource(get('config').logo)"
      />
      <h1 class="display-2">Registrierung</h1>

      <span>Schritt</span>
      <span v-text="step" />
      <span> von 3</span>
    </div>

    <!-- <v-divider v-if="step < 3" class="mx-2 mb-4"></v-divider> -->
    <v-card class="pa-4 mt-4">
      <v-card-title
        v-if="step < 5"
        class="title py-0 mt-4 font-weight-bold text-center"
      >
        <span>{{ currentTitle }}</span>
      </v-card-title>
      <v-form
        @submit.prevent="registerUser($event, get('config').subscription_reg)"
        ref="registerForm"
        v-model="valid"
      >
        <!-- In Step 1 sind 2 steps deeper inside -->
        <!-- Emailadresse & Passwort festlegen -->
        <v-window
          v-model="step"
          class="mx-4"
        >
          <v-window-item :value="1">

            <div>
              <!-- Emailadresse festlegen -->
              <v-window v-model="step_inside">
                <!-- Step 1 Inside-->
                <v-window-item :value="1">

                  <v-text-field
                    label="Wie lautet E-Mail-Adresse"
                    :rules="eMailRules"
                    prepend-inner-icon="mdi-email-outline"
                    name="email"
                    class="mt-4"
                    required
                    outlined
                  />

                  <div>
                    <input
                      type="hidden"
                      name="role"
                      class="form-control"
                      value="ROLE_END_USER"
                    >
                    <v-text-field
                      v-model="password"
                      label="Password"
                      prepend-inner-icon="mdi-lock"
                      type="password"
                      required
                      class="mb-n1"
                      name="password"
                      :append-icon="showPwIcon ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                      @click:append="showPwIcon = !showPwIcon"
                      :type="showPwIcon ? 'text' : 'password'"
                      loading
                      outlined
                      :rules="passwordRules"
                    >
                      <template v-slot:progress>
                        <v-progress-linear
                          :value="progress"
                          :color="color"
                          absolute
                          height="2"
                        ></v-progress-linear>
                      </template>
                    </v-text-field>

                    <v-checkbox
                      class="mt-0 pt-0"
                      name="Datenschutz"
                      v-model="datenschutzCheckbox"
                      label="Ich bin mit der Speicherung und weiteren Verwendung meiner Daten, sowie mit der Datenschutzerklärung und den AGB für die Nutzung der 'LKF Medithek' einverstanden."
                      required
                      :rules="[v => !!v || 'Zustimmung erforderlich!']"
                    >
                      <template v-slot:label>
                        <div class="pa-2 body-2">
                          Ich bin der Speicherung und weiteren Verwendung meiner Daten <br>
                          zur Bereitstellung dieses Dienstes einverstanden und bestätige <br>
                          die 
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <a
                                target="_blank"
                                @click="linkTo('Nutzungsbedingungen')"
                              >
                                Nutzungsbedingungen  
                              </a>
                            </template>
                            Öffnet in einem neuen Fenster
                          </v-tooltip> und 
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <a
                                target="_blank"
                                @click="linkTo('Datenschutzerklaerung')"
                              >
                                Datenschutzbestimmungen.  
                              </a>
                            </template>
                            Öffnet in einem neuen Fenster
                          </v-tooltip>

                        </div>
                      </template>
                    </v-checkbox>
                  </div>
                  <v-row class="justify-end">
                    <v-btn
                      type="submit"
                      color="success"
                      outlined
                      :loading="loadingStep"
                      :disabled="!valid"
                      class="mb-4 mr-4"
                    >
                      weiter
                    </v-btn>
                  </v-row>

                </v-window-item>

                <!-- Step 2 Inside-->
                <!-- Passwort festlegen -->
                <!-- <v-window-item :value="2">
                </v-window-item> -->
              </v-window>
            </div>

          </v-window-item>

          <v-window-item :value="2">


            <div class="row">
              <v-col
                v-for="(plan, index) in get('plans')"
                :key="plan.id"
                cols="6"
              >

                <v-icon
                  class="absoluteTR15 pa-4"
                  v-if="planChoosen"
                  @click="showCards()"
                >mdi-arrow-left</v-icon>
                <v-card class="sub-card pa-4">
                  <v-list-item
                    two-line
                    class=""
                  >
                    <v-list-item-content>
                      <v-list-item-title class="text-h5 font-weight-bold">
                        {{ plan.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ plan.description | truncate('99', '...') }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-card-text class="mt-0">
                    <div class="text-h3 primary--text font-weight-bold">
                      {{ formatCurrency(plan.price) }}
                    </div>
                    <span
                      class="subtitle-1 font-weight-normal"
                      v-if="plan.period == 'Monatlich'"
                    >
                      pro Monat
                    </span>
                    <span
                      class="subtitle-1 font-weight-normal"
                      v-if="plan.period == 'Jährlich'"
                    >
                      pro Jahr
                    </span>
                  </v-card-text>

                  <v-card-text v-if="plan.trial !== null">
                    <div>
                      <v-list-item
                        density="compact"
                        prepend-icon="mdi-calendar"
                      >
                        <v-icon
                          size="16px"
                          color="primary"
                          class="mr-1"
                        >mdi-calendar</v-icon>
                        <v-list-item-subtitle>1 {{ plan.trial }}</v-list-item-subtitle>
                      </v-list-item>
                    </div>
                  </v-card-text>

                  <v-card-text>
                    <v-btn
                      depressed
                      @click="createSubscription(plan, index)"
                      width="100%"
                      :loading="loading"
                    >
                      Auswählen und bezahlen
                    </v-btn>
                    <p class="caption grey--text text--darken-2 pa-0 mt-2 mb-0">Mit Klick auf den Button "Auswählen und
                      bezahlen" werden unsere </p>
                    <p class="caption grey--text text--darken-2 pa-0 mb-0">Zahlungsbedingungen und <a
                        target="_blank"
                        @click="linkTo('Nutzungsbedingungen')"
                      >AGB</a> akzeptiert. Der
                        Zugang wird nach </p>
                    <p class="caption grey--text text--darken-2 mb-6">erfolgter Zahlung sofort freigeschaltet.</p>
                  </v-card-text>
                </v-card>
              </v-col>

            </div>
            <v-row class="">

              <v-col>
                <div class="d-flex">
                  <v-col>
                    <div class="d-flex align-start">
                      <v-icon class="mr-2 text-h4 primary--text">mdi-check</v-icon><span class="body-1">Keine Verpflichtung
                        jederzeit kündbar.</span>
                    </div>
                  </v-col>
                  <v-col>
                    <div class="d-flex">
                      <v-icon class="mr-2 text-h4 primary--text">mdi-check</v-icon><span class="body-1">Sofortiger Zugriff
                        auf alle Videos.</span>
                    </div>
                  </v-col>
                </div>
                <div class="d-flex">
                  <v-col>
                    <div class="d-flex">
                      <v-icon class="mr-2 text-h4 primary--text">mdi-check</v-icon><span class="body-1">Bequem und sicher
                        bezahlen.</span>
                    </div>
                  </v-col>
                  <v-col>
                    <v-icon class="mr-2 text-h4 primary--text">mdi-check</v-icon><span class="body-1">Genieße die Videos von
                    </span>
                  </v-col>
                </div>

              </v-col>
            </v-row>


          </v-window-item>

          <!-- Bezahlung -->
          <v-window-item :value="3">

            <div id="paypal-subscription-container"></div>

          </v-window-item>

          <v-window-item :value="4">
            <div
              v-if="succesfullCreated == true"
              width="450px"
              class="pa-4 text--center"
            >
              <h1 class="green--text">Danke!</h1>
              <p>Dein Account ist aktiviert.</p>
            </div>

            <div
              v-else
              class="pa-4"
            >
              <h4>Es ist ein Fehler aufgetreten.</h4>
              <p>{{ this.errorMessage }}</p>
              <v-btn
                outlined
                @click="step = 2"
              >
                Bitte andere E-Mailadresse verwenden
              </v-btn>
            </div>

          </v-window-item>

        </v-window>

      </v-form>
    </v-card>

    <div class="d-flex justify-center">
      <v-btn
        @click="linkTo('login')"
        text
        small
        class="mt-4 grey--text"
      >zurück zum Login</v-btn>
    </div>
  </div>
</template>

<script>

import APIService from '@/services/api.service';
import AppService from '@/services/app.service';
// import userRole from '@/services/userRoleService';
import { mapGetters } from 'vuex';
// import response from '@/components/response/response';

import registerForm from '@/components/register/registerForm';
import Payment from '@/services/payment.service';

const google = () => import('@/components/sso/google')

export default {
  name: 'registerForm',
  components: {
    response: () => import('@/components/response/response'),
    // registerForm: () => import('@/components/register/registerForm'),
  },
  extends: registerForm,
  data() {
    return {
      el: 1,
      step: 1,
      step_inside: 1,
      email: '',
      valid: true,
      yearly: false,
      loading: false,
      loadingStep: false,
      password: '',
      showPwIcon: false,
      datenschutzCheckbox: false,
      emailIsValid: Boolean,
      passwordIsValid: Boolean,
      planChoosen: false,
      appName: '',
      customerLogo: '',
      errorMessage: '',
      succesfullCreated: false,
      subReg: '',
      plan: {},
      eMailRules: [
        v => !!v || 'Die E-Mailadresse ist erforderlich.',
        v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Falsches Format für eine E-Mailadresse'
      ],
      passwordRules: [
        v => !!v || 'Bitte Passwort eingeben',
        v => (v && v.length >= 6) || 'Das Passwort ist zu kurz'
      ],
    }
  },

  computed: {
    ...mapGetters(["get"]),
    progress() {
      return Math.min(100, this.password.length * 16.6667)
    },
    color() {
      return ['error', 'warning', 'success'][Math.floor(this.progress / 50)]
    },

    currentTitle() {
      switch (this.step) {
        case 1:
          if (this.step_inside == 2) {

            return 'Vergebe ein Passwort'
          }
          return 'Bitte E-Mail und Passwort eingeben'
        case 2: return 'Wähle einen Plan.'
        case 3: return 'Zahlung!'
        default: return ''
      }
    },
  },

  methods: {

    formatCurrency(number) {
      return new Intl.NumberFormat('de-DE',
        {
          style: 'currency',
          currency: 'EUR'
        })
        .format(number)
    },

    registerUser(e, subReg) {

      this.loadingStep = true
      var payload = APIService.getFormData(APIService.getForm(e))

      payload.active = subReg
      payload.role = 'ROLE_END_USER'

      this.$store.dispatch('post',
        {
          endpoint: '/api/v1/users',
          event: payload,
          header: false,
          message: false
        }
      ).then(() => {
        this.succesfullCreated = true;
        this.loadingStep = false
        payload.app = btoa(`${this.$store.state.main.config.result.licence}:`);
        if (subReg == false) {
          this.$store.dispatch('auth/login', payload)
          this.step = 4
        } else {
          this.step = 2
        }
        this.loading = false;
        // APIService.setResponse({ message: 'test', color: "blue" });
      }).catch((error) => {
        const { response: { status } } = error;
        this.loading = false;
        if (status === 422) {
          APIService.setResponse({ message: "Der E-Mailserver ist inaktiv", setRegisterError: true, color: "red" });
          APIService.setRegisterResponse({ setRegisterError: true });
          this.step_inside = 1
        }
        else {
          APIService.setResponse({ message: "Der E-Mailserver ist inaktiv", setRegisterError: true, color: "red" });
        }
      })
    },

    renderSumUp() {
      return 0
    },

    showCards() {
      const subCard = Array.from(document.querySelectorAll('.sub-card'))
      subCard.forEach(card => {
        card.classList.remove('hideIt')
        card.classList.add('showIt')
      })
      this.planChoosen = false
    },

    createSubscription(plan, index) {
      // this.step = 2;
      this.loading = true
      // this.selectedIndex = index

      this.planChoosen = true
      const subCard = Array.from(document.querySelectorAll('.sub-card'))
      const fadeOutClass = "fade-out-1s";
      const hideClass = "hideIt";
      const fadeInClass = "fade-in-1s";
      const showClass = "showMe";

      var selectedSub = index

      subCard.forEach((card, index) => {
        if (index !== selectedSub) {
          card.classList.add(hideClass);
          card.classList.remove(showClass);
        } else {
          setTimeout(() => {
            card.style.transition = "all 1s ease-in-out";
          }, 500);
        }
      })

      const payload = {};
      payload.plan = plan

      this.$store.dispatch('fetch', { endpoint: `/api/v1/providers`, state: 'providers' })
        .then((response) => {
          Payment.subscription(this, response.result, payload, ['setRegisterResponse', { setRegisterSuccess: true }]);
          this.step = 3
          this.$store.dispatch('auth/login', payload)
          this.loading = false
        })
    },

    linkTo(route) {
      this.$router.push('/' + route).catch(() => {
        APIService.setResponse({ message: "Hier bist du bereits", color: "blue" })
      });
    },

    imageSource(item, width, height) {
      return AppService.imageSource(item, width, height);
    },

  },
}
</script>

<style lang="sass">

  .hideIt
    display: none
  .click_copy
    cursor: copy
  .click_cursor
    cursor: pointer
  .chevron-left
    font-size: 0.75rem
  .fade-enter-active, .fade-leave-active
    transition: opacity .5s

  @media only screen and (max-width: 600px)
    #loginCard
      margin-top: 5% !important

  </style>
