<template>
	<div v-if="get('galleries') != 0 && get('galleries').some(gallery => gallery.published)">
		<v-row class="pa-0 ma-0">
			<span @click="linkTo('galleries')" class="mt-6 mb-2 text-h5 font-weight-medium primary--text click_cursor">Galerien</span>
		</v-row>
		<div v-if="get('galleries') != 0">
			<vueper-slides
				class="no-shadow"
				:visible-slides="5"
				slide-multiple
				pauseOnHover
				:breakpoints="breakpoints"
				:gap="1"
				fixed-height="340px"
				:dragging-distance="20"
				:arrows-outside="false"
				:bullets="false"
				>
				<!-- @before-slide="loadMoreGalleries" -->
				<vueper-slide
					v-if="gallery.published"
					v-for="(gallery, index) in get('galleries')"
					:key="index"
				>
					<template #content>
						<v-card
							class="mx-auto"
							@click="openGallery(gallery)"
							height="340px"
							style="border-radius: 10px;"	
						>
							<v-img
								:src="imageSource(gallery.images[0]?.path)"
								lazy
								height="65%"
								cover
							>
								<div class="d-flex justify-space-between">
									<v-chip
										class="ml-4 mt-4"
									>
										<v-icon
											left
											size="16"
										>
											mdi-image-outline
										</v-icon>
										<span>
											{{ gallery.images.length }} {{ gallery.images.length === 1 ? 'Bild' : 'Bilder' }} </span>
									</v-chip>
									<div
										v-if="!isPaid(gallery) || !isSubscription(gallery)"
										class="mr-4 mt-4"
									>
										<v-chip
											v-if="!checkAccess(gallery)"
											color="primary"
										>
											<v-icon
												left
												size="18"
											>
												mdi-lock-outline
											</v-icon>
											<div
												v-if="!gallery.subscription"
											>
												{{gallery.price}}€
											</div>
											<div
												v-else
											>
												<div
													v-for="(plan, index) in gallery.plans.slice(0, 1)"
													:key="index"
												>
													Nur im Abo {{ plan.name }}
												</div>
											</div>
										</v-chip>
									</div>
								</div>	
							</v-img>

							<v-card-text
								class="pa-4 click_cursor"
								style="position: relative"
							>
								<h3 class="title font-weight-medium">
									{{ gallery.title | truncate("40", "...") }}
								</h3>
								<div class="font-weight-light caption d-flex align-center mt-1">
									<v-icon
										left
										size="12"
										color="grey"
									> mdi-calendar </v-icon>
									{{ zeitInText(gallery.created) }}
								</div>

								<div
									v-if="gallery.description != undefined || ''"
									class="body-2 mt-1"
								>
									{{ gallery.description | truncate("40", "...") }}
								</div>
								<div
									v-else
									class="body-2"
								>Keine Beschreibung vorhanden</div>
							</v-card-text>
						</v-card>
					</template>
				</vueper-slide>
				<!-- 
				<div class="d-flex align-center justify-center full-w" style="min-width: 33%;">
					<v-btn @click="linkTo('galleries')" outlined xxl-large>Weitere Galerien</v-btn>
				</div> -->
			</vueper-slides>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import AppService from '@/services/app.service';
import APIService from '@/services/api.service';
import { VueperSlides, VueperSlide } from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';
import moment from 'moment';
import { infiniteScrollMixin } from '@/mixins/infiniteScrollMixin';

export default {
	name: 'gallerySlider',
	mixins: [infiniteScrollMixin],

	props: ['gallery'],
	components: { VueperSlides, VueperSlide },

	data() {
		return {
			title: '',
			description: '',
			published: false,
			infiniteScrollState: 'galleries',
			pagination: {
				galleries: [],
				limit: 10,
				total: 0
			},
			breakpoints: {
				1800: {
					visibleSlides: 3,
					slideMultiple: 3
				},
				1199: {
					visibleSlides: 2,
					slideMultiple: 2,
				},
				800: {
					visibleSlides: 1,
					bulletsOutside: true
				}
			},
		}
	},

	computed: {
		...mapGetters(["get"])
	},

	methods: {

		imageSource(image, width, height) {
			return AppService.imageSourceWithToken(image, width, height);
		},

		linkToGalleryImages(galleryId) {
			this.$store.dispatch('fetch', { endpoint: `/api/v1/galleries/${galleryId}/images?sort=DESC`, state: 'galleryImages' })
				.then(() => {
					this.$router.push(`gallery/${galleryId}/images`)
				})
		},

		// loadMoreGalleries(){
		// 	this.loadMore()
		// },

		formDate(value) {
			if (value) {
				return moment(String(value)).format('DD.MM.YYYY')
			}
		},

		isTester() {
			return AppService.isTester()
		},
		isCreatorSubActive() {
			return AppService.isCreatorSubActive()
		},

		openModal(name) {
			this.$store.dispatch("modal/open", name)
		},

		linkTo(route) {
			this.$router.push('/' + route)
		},

		openGallery(gallery) {
			if (gallery.price > 0) {
				if (!AppService.isPaid(gallery, 'gallery')) {
					return this.addToCart(gallery)
				}
			}
			if (gallery.subscription) {
				if (!AppService.isSubscription(gallery)) {
					return this.linkTo('subCheckout');
				}
			}
			return this.linkToGalleryImages(gallery.id);
		},

		checkAccess(gallery) {
			if (gallery.price > 0) {
				return AppService.isPaid(gallery, 'gallery')
			}
			if (gallery.subscription) {
				return AppService.isSubscription(gallery)
			}
			return true
		},

		isPaid(item) {
			return AppService.isPaid(item, 'videos');
		},

		isSubscription(item) {
			return AppService.isSubscription(item);
		},

		zeitInText(date) {
			return AppService.getTimeElapsed(date);
		},

		isFree(item) {
			let free = false;
			if (item.price == 0 && item.subscription == false) {
				free = true;
			}
			return free;
		},

		addToCart(gallery) {
			this.$store.dispatch('fetch', { endpoint: '/api/v1/cart', state: 'cart' })

			const cartItems = this.$store.state.main.cart.result;
			const product_id = gallery.id;

			const isProductIdPresent = (arr, productId) => {
				return arr.some(obj => obj.product_id === productId);
			};

			if (this.isPaid(gallery) || !isProductIdPresent(cartItems, product_id)) {
				this.$store.dispatch('post',
					{
						endpoint: `/api/v1/cart`,
						state: 'cart',
						event: { type: "Gallery", productId: gallery.id },
						message: false,
					}).then(() => {
						this.$store.dispatch('fetch', { endpoint: '/api/v1/cart', state: 'cart' });
						APIService.setResponse({ message: "Zum Warenkorb hinzugefügt", color: "green" });
					})
			} else {
				APIService.setResponse({ message: "Bereits im Warenkorb enthalten", color: "red" });
			}
		},
	},
}
</script>
