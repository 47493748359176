<template>
  <v-app>

    <v-navigation-drawer
      v-model="drawer"
      v-if="currentUser(['ROLE_TENANT'])"
      app
    >
      <div class="mx-auto my-4 text-center">
        <v-img
          alt="Logo"
          class="shrink mx-auto mb-2 click_cursor"
          contain
          :src="imageSource(get('config').logo, '', 75)"
          transition="scale-transition"
          height="75px"
        />

        <div
          v-if="!mini"
          class="text-h6 font-weight-bold click_cursor"
        >
          {{ site_name }}
        </div>
      </div>

      <v-list nav active-class>
        
        <!-- Dashboard Link (einzeln) -->
        <v-list-item
          link
        >
          <v-list-item-icon>
            <v-icon
              v-text="menuItems[0].icon"
              :class="checkProduct(menuItems[0].product) ? '' : 'secondary--text'"
            ></v-icon>
          </v-list-item-icon>
          <v-list-item-content @click="linkTo(menuItems[0].url)">
            <v-list-item-title
              v-text="menuItems[0].text"
              :class="checkProduct(menuItems[0].product) ? '' : 'secondary--text'"
            ></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Upload Links (beide) -->
        <v-list-group
          v-model="uploadSelected"
          :value="true"
          prepend-icon="mdi-upload"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Upload</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            class="px-4"
            v-for="(item, i) in uploadMenuItems"
            :key="i"
            link
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content @click="linkTo(item.url)">
              <v-list-item-title
                v-text="item.text"
                :class="checkProduct(item.product) ? '' : 'secondary--text'"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <!-- Content Links (alle)-->
        <v-list-group
          v-model="contentSelected"
          :value="true"
          prepend-icon="mdi-table-of-contents"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Content</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            class="px-4"
            v-for="(item, i) in contentMenuItems"
            :key="i"
            link
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content @click="linkTo(item.url)">
              <v-list-item-title
                v-text="item.text"
                :class="checkProduct(item.product) ? '' : 'secondary--text'"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <!-- Normale Links (alle)-->
        <v-list-item
          v-for="(item, i) in menuItems.slice(1)"
          :key="i"
          link
          exact-path
        >
          <v-list-item-icon v-if="item.icon != 'mdi-upload'">
            <v-icon
              v-text="item.icon"
              :class="checkProduct(item.product) ? '' : 'secondary--text'"
            ></v-icon>
          </v-list-item-icon>
          <v-list-item-content @click="linkTo(item.url)">
            <v-list-item-title
              v-text="item.text"
              :class="checkProduct(item.product) ? '' : 'secondary--text'"
            ></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- Ende Normale Links -->
      </v-list>

    </v-navigation-drawer>


    <v-app-bar
      v-if="currentUser(['ROLE_TENANT', 'ROLE_USER', 'ROLE_END_USER'])"
      app
      flat
    >

      <div
        class="pr-4"
        v-if="currentUser(['ROLE_TENANT'])"
      >
        <v-icon
          @click="drawer = !drawer"
          color="#00C3FF"
        >mdi-menu</v-icon>
      </div>

      <!-- Menü für Consumer -->
      <div
        v-if="currentUser(['ROLE_USER', 'ROLE_END_USER']) || (currentUser(['ROLE_TENANT']) && ['/', '/home', '/category', '/playlists', '/galleries', '/images'].includes($route.path))"
        class="d-flex align-center click_cursor"
      >
        <v-img
          alt="Logo"
          class="shrink mr-2 ml-1 click_cursor"
          contain
          :src="imageSource(get('config').logo)"
          transition="scale-transition"
          width="32"
        />
        <h2
          @click="linkTo('home')"
          class="ml-2 mr-6 line-height-1"
        >
          {{ site_name }}
        </h2>
        <div
          v-for="(link, index) in consumerLinks"
          :key="index"
          class="hidden-md-and-down"
        >
          <span
            text
            class="mr-6"
            :class="{
              'font-weight-bold': isActiveLink(link.url),
              'primary--text': isActiveLink(link.url)
            }"
            v-if="checkProduct(link.product)"
            @click="linkTo(link.url)"
          >
            {{ link.text }}
          </span>
        </div>



      </div>

      <v-row class="hidden-md-and-down">
        <v-col
          cols="3"
          class="mr-3"
        >
          <search />
        </v-col>
      </v-row>

      <v-spacer></v-spacer>


      <div
        v-if="get('cart')"
        @click="openCartDrawer(cartDrawerState)"
        class="mr-6 click_cursor"
      >
        <v-badge
          :content="get('cart').length"
          :value="get('cart').length"
          overlap
        >
          <v-icon
            color="#00C3FF"
            size="32px"
          >mdi-cart</v-icon>
        </v-badge>
      </div>

      <div v-if="currentUser(['ROLE_TENANT'])">
        <v-btn
          v-if="isTester() && !isCreatorSubActive()"
          rounded
          small
          class="mr-4 animated-gradient-dBlue-red-radial"
          @click="linkTo('checkout')"
        >
          Upgrade Plan
        </v-btn>

      </div>
      <div
        class="hidden-lg-and-up"
        v-if="currentUser(['ROLE_END_USER'])"
      >
        <v-icon
          v-if="!mobileDrawer"
          @click="openMobileMenu()"
        >mdi-menu</v-icon>
        <v-icon
          v-else
          @click="mobileDrawer = !mobileDrawer"
        >mdi-close</v-icon>
      </div>


      <div>
        <v-menu
          open-on-click
          offset-x-bottom
          v-if="currentUser(['ROLE_TENANT', 'ROLE_USER', 'ROLE_END_USER'])"
        >
          <template v-slot:activator="{ on, attrs }">

            <div
              class="pr-4 d-flex"
              v-bind="attrs"
              v-on="on"
            >
              <v-avatar
                class="mr-1 click_cursor"
                width="32"
                height="32"
                outline
              >
                <img
                  :src="imageSource(get('user').image)"
                  alt="avatar"
                  width="32"
                >
                <!-- <img :src="userImage" alt="avatar" width="32"> -->
              </v-avatar>
            </div>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-theme-light-dark</v-icon>
              </v-list-item-icon>
              <v-switch
                @click="toogleDarkTheme"
                hide-details
                inset
                dense
                class="mt-n1"
              >
              </v-switch>
            </v-list-item>

            <v-list-item
              v-if="currentUser(['ROLE_END_USER', 'ROLE_USER'])"
              @click="linkTo('userprofil')"
            >
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                <span class="pl-n4">Profil</span>
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              v-else
              @click="linkTo('admin_profil')"
            >
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                <span class="pl-n4">Profil Settings</span>
              </v-list-item-title>
            </v-list-item>

            <v-divider></v-divider>
            <v-list-item
              v-on:submit.prevent
              @click="logout()"
              link
            >
              <v-list-item-icon>
                <v-icon>mdi-logout-variant</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                <span class="pl-n4">Logout</span>
              </v-list-item-title>
            </v-list-item>

          </v-list>
        </v-menu>
      </div>

    </v-app-bar>

    <v-navigation-drawer
      v-if="currentUser(['ROLE_END_USER'])"
      v-model="mobileDrawer"
      temporary
      right
      fixed
    >
      <v-list
        nav
        dense
      >
        <v-list-item>
          <v-list-item-content>
            <div class="d-flex justify-space-between">
              <v-list-item-title class="primary--text headline font-weight-medium my-2">Menü</v-list-item-title>
              <v-icon
                class="click_cursor"
                size="21"
                color="primary"
                @click="mobileDrawer = !mobileDrawer"
              >mdi-close</v-icon>
            </div>
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="d-flex">
          <v-list-item-icon>
            <v-list-item-title>Toogle Theme</v-list-item-title>
          </v-list-item-icon>
          <v-spacer></v-spacer>
          <v-icon size="18">mdi-theme-light-dark</v-icon>
          <v-switch
            @click="toogleDarkTheme"
            hide-details
            inset
            dense
            class="mt-n1 ml-2 mr-n3"
          />
        </v-list-item>

        <v-list-item
          v-for="(link, index) in consumerLinks"
          :key="index"
          @click="linkTo(link.url)"
          link
        >
          <v-list-item-title v-if="checkProduct(link.product)">{{ link.text }}</v-list-item-title>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item
          v-on:submit.prevent
          @click="logout()"
          link
        >
          <!-- <v-list-item-icon>
            <v-icon>mdi-logout-variant</v-icon>
          </v-list-item-icon> -->
          <v-list-item-title>
            <span class="pl-n4">Logout</span>
          </v-list-item-title>
        </v-list-item>

      </v-list>
    </v-navigation-drawer>

    <response />

    <rightSiteMenu />

    <v-dialog
      v-model="cookieDialog"
      max-width="250px"
    >
      <div class="d-flex align-center">
        <v-card class="pa-6">
          <v-icon
            class="headline primary--text mb-4"
            color="primary"
            size="32"
          >mdi-cookie</v-icon>
          <p class="headline">Cookies</p>
          <p class="pr-4">
            Wir verwenden Cookies für den Betrieb unserer Seite.
            <span
              class="click_cursor primary--text"
              @click="linkTo('Datenschutzerklaerung')"
            >Hier</span>
            findest du alles zu unserem Datenschutz.
          </p>
          <div class="d-flex justify-end">
            <v-btn
              text
              color="primary"
              @click="setCookie"
            >
              Okay.
            </v-btn>
          </div>
        </v-card>
      </div>
    </v-dialog>

    <v-theme-provider>
      <v-main>
        <v-scroll-x-transition>
          <router-view />
        </v-scroll-x-transition>
      </v-main>
    </v-theme-provider>

    <v-footer
      v-if="currentUser(['ROLE_TENANT', 'ROLE_END_USER'])"
      inset
      app
      width="100%"
    >
      <v-row class="ma-0 pa-0 flex justify-center grey--text">
        <span class="caption text-center ">
          © {{ new Date().getFullYear() }} build and powered by
          <a
            href="https://liox.io"
            target="_blank"
            class="text-center grey--text font-weight-bold"
          >
            LIOX.io
          </a>
        </span>
        <span class="px-1 text-caption"> | </span>
        <span class="caption text-center">Alle Rechte vorbehalten</span>
        <span class="px-1 text-caption"> | </span>
        <span
          class="caption text-center text--lighten click_cursor"
          @click="linkTo('Datenschutzerklaerung')"
        >Datenschutz</span>
        <span class="px-1 text-caption"> | </span>
        <span
          class="caption text--lighten click_cursor text-center"
          @click="linkTo('Nutzungsbedingungen')"
        > Nutzungsbedingungen</span>
        <span class="px-1 text-caption"> | </span>
        <span
          class="caption text--lighten click_cursor text-center"
          @click="linkTo('content-removal')"
        > Inhalte melden</span>
        <span class="px-1 text-caption"> | </span> 
        <span
          class="caption text--lighten click_cursor text-center"
          @click="linkTo('depicture-removal')"
        > Abbildung melden</span>
        <span class="px-1 text-caption"> | </span>
        <span
          class="caption text--lighten click_cursor text-center"
          @click="linkTo('Impressum')"
        > Impressum</span>
        <span class="px-1 text-caption"> | </span>
        <span
          class="caption text--lighten click_cursor text-center"
          @click="openModal('feedbackForm')"
        > Support</span>
      </v-row>
    </v-footer>


    <component :is="isSocialSharing" />

    <feedbackForm />

  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import userRole from '@/services/userRoleService';
import APIService from '@/services/api.service';
import AppService from '@/services/app.service';
import Payment from '@/services/payment.service';

const socialSharing = () => import('@/components/modal/socialSharing');
const rightSiteMenu = () => import('@/components/rightSiteMenu/rightSiteMenu');

export default {

  name: 'App',
  components: {
    response: () => import('@/components/response/response'),
    search: () => import('@/components/search/globalSearch'),
    feedbackForm: () => import('@/components/modal/feedbackForm'),
  },
  data() {
    return {
      title: 'Mediathek',
      loading: false,
      val: Boolean,
      cartDrawerState: true,
      normSelected: 0,
      uploadSelected: 0,
      contentSelected: 0,
      mobileDrawer: false,
      drawer: true,
      cookieDialog: false,
      mini: false,
      isLoading: false,
      items: [],
      model: null,
      remainTester: true,
      search: null,
      admins: [
        ['Management', 'mdi-account-multiple-outline'],
        ['Settings', 'mdi-cog-outline'],
      ],
      cruds: [
        ['Create', 'mdi-plus-outline'],
        ['Read', 'mdi-file-outline'],
        ['Update', 'mdi-update'],
        ['Delete', 'mdi-delete'],
      ],
      uploadMenuItems: [
        { text: 'Video Upload', url: 'video-upload', icon: 'mdi-video-plus', product: 'Starter' },
        { text: 'Image Upload', url: 'image-upload', icon: 'mdi-image-plus', product: 'Starter' },
      ],
      contentMenuItems: [
        { text: 'Videos', icon: 'mdi-video-account', url: 'videos', product: 'Starter' },
        { text: 'Playlisten', icon: 'mdi-folder-play-outline', url: 'playlisten', product: 'Starter' },
        { text: 'Kategorien', icon: 'mdi-view-list', url: 'categories', product: 'Starter' },
      ],
      menuItems: [
        { text: 'Dashboard', icon: 'mdi-view-dashboard-variant', url: 'admin', product: 'Starter' },
        { text: 'Meine Seite', icon: 'mdi-theater', url: 'home', product: 'Starter' },
        // { text: 'Live Streams', icon: 'mdi-video-wireless-outline', url: 'streams', product: 'Premium'},
        { text: 'Statistiken', icon: 'mdi-finance', url: 'stats', product: 'Starter' },
        { text: 'Nachrichten', icon: 'mdi-chat', url: 'chats', product: 'Starter' },
        { text: 'Benutzer', icon: 'mdi-account-group', url: 'user', product: 'Starter' },
        // { text: 'E-Mail Templates', icon: 'mdi-bell-cog-outline', url: 'notification', product: 'Starter' },
        { text: 'Einstellungen', icon: 'mdi-application-cog-outline', url: 'einstellungen', product: 'Starter' },
        { text: 'Landingpage', icon: 'mdi-airplane-landing', url: '/', product: 'Starter' },
      ],
      consumerLinks: [
        { text: 'Kategorien', url: 'category', product: 'Starter' },
        { text: 'Playlisten', url: 'playlists', product: 'Starter' },
        // { text: 'Live-Streams', url: 'live-streams', product: 'Premium' },
        // { text: 'Favoriten', url: 'favorits', product: 'Starter' }
      ],
    }
  },

  computed: {
    ...mapGetters(["get"]),

    site_name: {
      get() {
        if (typeof this.$store.state.main.config.result !== "undefined") return this.$store.state.main.config.result.site_name
      }
    },

    isSocialSharing: {
      get() {
        if (typeof this.$store.state.main.video.result !== "undefined") return socialSharing;
      },
      set(val) {
        this.$emit("input", val)
      }
    },

    currentRouteName() {
      return this.$route.name;
    }
  },

  methods: {

    setCookie() {
      AppService.setCookie('cookieBanner', 'true');
      this.cookieDialog = false;
    },

    imageSource(item, width, height) {
      return AppService.imageSource(item, width, height);
    },

    isTester() {
      return AppService.isTester()
    },
    isCreatorSubActive() {
      return AppService.isCreatorSubActive()
    },

    checkProduct(product) {
      return AppService.hasProduct(AppService.getProducts(product))
    },

    upgradePlan(name) {
      this.$store.dispatch('fetch', { endpoint: `/api/v1/products`, host: 'getWebshopHost', state: 'products' })
      this.$store.dispatch('fetch', { endpoint: `/api/v1/products/${this.$store.state.main.licence.result.product.crm_id}`, host: 'getWebshopHost', state: 'product' })
      this.$store.dispatch('modal/open', name)
    },
    logout() {
      this.$store.dispatch('auth/logout');
    },
    currentUser(roles) {
      return userRole.hasRole(roles);
    },

    linkTo(route) {
      AppService.linkTo(route, this.$router)
    },
    // linkTo(route) {
    //   this.$router.push('/' + route)
    // },

    imageSource(item) {
      let src = './assets/logo/LogoMark_blue_new.png';
      if (item != null) {
        src = APIService.getApiHost() + `/${item}`;
      }
      return src;
    },

    openModal(name) {
      this.$store.dispatch("modal/open", name)
    },

    openCartDrawer(cartDrawerState) {
      this.$store.dispatch('fetch', { endpoint: `/api/v1/cart`, state: 'cart' })
      this.$store.dispatch('fetch', { endpoint: `/api/v1/providers`, state: 'providers' })
        .then((response) => {
          Payment.checkout(this, response.result, 'EUR', ['reload', null]);
        })
      this.$store.commit('rightSiteDrawer/toogle', cartDrawerState)

    },

    openMobileMenu() {
      this.mobileDrawer = !this.mobileDrawer
    },

    toogleDarkTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.$store.dispatch('patch', {
        endpoint: `/api/v1/users/${this.$store.state.main.user.result.id}`,
        event: {
          dark_theme: this.$vuetify.theme.dark
        },
        message: false
      })
    },

    isActiveLink(path) {
      const routePath = this.$route.path;
      const trimmedRoutePath = routePath.startsWith('/') ? routePath.slice(1) : routePath;
      const trimmedPath = path.startsWith('/') ? path.slice(1) : path;

      return trimmedRoutePath === trimmedPath || trimmedRoutePath.startsWith(trimmedPath + '/');
    }
  },

  watch: {
    model(val) {
      if (val != null) this.tab = 0
      else this.tab = null
    },
    search(val) {
      // Items have already been loaded
      if (this.items.length > 0) return

      this.isLoading = true

      // Lazily load input items
      this.$store.dispatch('fetch', {
        endpoint: `/api/v1/search/?q=${val}`,
        state: 'search',
        host: 'getApiHost'
      }).then((res) => {
        this.items = res.result.categorie
      }).catch(err => {
        console.log("Error | Aus der Search auf App.Vue", err)
      })
        .finally(() => (this.isLoading = false));

    },
  },

  mounted() {
    if (this.$store.state.auth.status.loggedIn) {
      this.$store.dispatch('fetch', {
        endpoint: `/api/v1/public`,
        state: 'config'
      })
        .then(() => {
          this.$store.dispatch('fetch', {
            endpoint: `/api/v1/licences/${this.$store.state.main.config.result.licence}`,
            state: 'licence',
            host: 'getAuthHost'
          })
            .then(() => {
              this.$store.dispatch('fetch', {
                endpoint: `/api/v1/users/me`,
                state: 'webshop_user',
                host: 'getWebshopHost'
              })

              this.$store.dispatch('fetch', {
                endpoint: `/api/v1/users/me`,
                state: 'user'
              })
                .then(() => {
                  APIService.setUser(this.$store.state.main.user.result);
                  if (this.$store.state.main.user.result != undefined) {
                    this.$vuetify.theme.dark = this.$store.state.main.user.result.dark_theme;
                  }
                })

              this.$store.dispatch('fetch', { endpoint: `/api/v1/cart`, state: 'cart' })
                .then(() => {
                  // this.$router.replace(window.location.pathname);
                })
            })
            .catch(() => { })
        })
        .catch(() => {
          this.$router.push('/install');
        });
    }
    else {
      this.$vuetify.theme.dark = true;
      // this.$store.dispatch('fetch', { 
      //   endpoint: '/api/v1/shop/', 
      //   state: 'shop',
      //   header: false
      // })
      this.$store.dispatch('fetch', {
        endpoint: `/api/v1/public`,
        state: 'config',
        header: false
      }).then(() => {
        document.title = this.$store.state.main.config.result.site_name
        if (AppService.isExplicit()) {
          if (!AppService.getCookie('explicitConsent')) {
            this.$router.push('/explicitwarning')
          }
        }
      })
    }

    // ####################
    // UNDER CONSTRUCTION
    // Cookies
    // ####################

    // // Check if the user has accepted the cookie banner
    if (!AppService.getCookie('cookieBanner')) {
      // Show the cookie banner
      this.cookieDialog = true
    }
    // ####################
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: 'Open Sans';
  src: url('@/assets/fonts/Open_Sans/OpenSans-VariableFont_wdth,wght.ttf');
}

@font-face {
  font-family: 'Space Grotesk';
  src: url('@/assets/fonts/Space_Grotesk/SpaceGrotesk-VariableFont_wght.ttf');
}

// $font-family: "Space Grotesk";
$font-family: "Open Sans";

.v-application {
  // [class*="text-"] {
  //   font-family: $font-family, sans-serif !important;
  // }
  font-family: $font-family, sans-serif !important;
}

.fab,
.far,
.fas {
  color: #00c3ff;
}

/*Rules for sizing the icon*/
.v-icon.md-14 {
  font-size: 14px !important;
}

.v-icon.md-16 {
  font-size: 16px !important;
}

.v-icon.md-18 {
  font-size: 18px !important;
}

.v-icon.md-24 {
  font-size: 24px !important;
}

.v-icon.md-36 {
  font-size: 36px !important;
}

.v-icon.md-48 {
  font-size: 48px !important;
}

.v-icon.md-64 {
  font-size: 64px !important;
}

.brandColor {
  // color: #00c3ff;
  color: #00aeef;
}

/* NOTE: SELECTS ENTIRE SCROLLBAR ON ALL ELEMENTS (*) IT IS POSSIBLE TO STYLE JUST CERTAIN ELEMENTS */
*::-webkit-scrollbar {
  width: 6px;
  /* NOTE: THIS ONLY AFFECTS THE WIDTH OF VERTICAL SCROLL BARS */
  height: 6px;
  /* NOTE: THIS ONLY AFFECTS THE HEIGHT OF HORIZONTAL SCROLL BARS */
}

/* NOTE: THIS IS THE TRACK THAT THE POSITIONING TAG SLIDES ON */
*::-webkit-scrollbar-track {
  background-color: #002b39;
  // margin-block: 5px;
  // border-radius: 100vmax;                    /* NOTE: THIS WILL ROUND THE EDGES, USE 100VMAX TO TOTALLY ROUND THE CORNERS OR A FEW PIXELS TO MAKE IT MORE SQUARISH */
}

*::-webkit-scrollbar-thumb {
  // LIOX Blue
  // background-color: rgb(67, 195, 255);
  // background-color: rgb(39, 39, 79); ???
  // background-color: rgb(53, 150, 243);

  background-color: #009ccc;
  border: 0px solid rgb(22, 96, 145);
  /* NOTE: THIS WILL MAKE THE THUMB (OR PILL) APPEAR TO FIT INSIDE THE TRACK */
  border-radius: 3px;
}

/* NOTE: THIS WILL AFFECT THE SCROLLBAR UPON HOVER BY THE MOUSE */
*::-webkit-scrollbar-thumb:hover {
  background-color: rgb(58, 172, 223);
}

@supports (scrollbar-color: blue green) {

  /* NOTE: THIS IS FOR FIREFOX SUPPORT (CHROME/EDGE HAVE THE MOST SUPPORT FOR STYLING) */
  * {
    scrollbar-color: rgb(111, 197, 255) rgb(22, 96, 145);
    scrollbar-width: thin;
    /* NOTE: CAN ALSO BE auto */
  }
}

// absolute Top left 11
.absoluteTL11 {
  position: absolute;
  top: 11px;
  left: 11px;
  z-index: 9;
}

// absolute Top right 11
.absoluteTR11 {
  position: absolute;
  top: 11px;
  right: 11px;
  z-index: 9;
}

// absolute Top right 11
.absoluteBL11 {
  position: absolute;
  bottom: 11px;
  left: 11px;
  z-index: 9;
}

// absolute Top right 11
.absoluteBR11 {
  position: absolute;
  bottom: 11px;
  right: 11px;
  z-index: 9;
}

// // Scrollbar für Firefox
// .scroller {
//   overflow-y: scroll;
//   scrollbar-color: #00c3ff #C2D2E4;
//   scrollbar-width: thin;
// }

// absolute Top left 22
.absoluteTL22 {
  position: absolute;
  top: 22px;
  left: 22px;
  z-index: 9;
}

// absolute Top right 22
.absoluteTR22 {
  position: absolute;
  top: 22px;
  right: 22px;
  z-index: 9;
}

// absolute Top right 22
.absoluteBL22 {
  position: absolute;
  bottom: 22px;
  left: 22px;
  z-index: 9;
}

// absolute Top right 22
.absoluteBR22 {
  position: absolute;
  bottom: 22px;
  right: 22px;
  z-index: 9;
}


nav li:hover,
nav li.router-link-active,
nav li.router-link-exact-active {
  background-color: indianred;
  cursor: pointer;
}

.vhl-list {
  overflow-x: hidden !important;
}

// absolute Top left 22
.absoluteTL33 {
  position: absolute;
  top: 33px;
  left: 33px;
  z-index: 9;
}

// absolute Top right 33
.absoluteTR33 {
  position: absolute;
  top: 33px;
  right: 33px;
  z-index: 9;
}

// absolute Top right 33
.absoluteBL33 {
  position: absolute;
  bottom: 33px;
  left: 33px;
  z-index: 9;
}

// absolute Top right 33
.absoluteBR33 {
  position: absolute;
  bottom: 33px;
  right: 33px;
  z-index: 9;
}

.click_cursor {
  cursor: pointer;
}

.zoom-in_cursor {
  cursor: zoom-in
}

.zoom-out_cursor {
  cursor: zoom-out
}

.btnNoHover:before {
  display: none;
}


video::-webkit-media-controls-enclosure {
  display: none !important;
}

.absoluteBottom {
  position: absolute;
  bottom: 15px;
  left: 15px
}

// .v-input--selection-controls .v-input__slot > .v-label, .v-input--selection-controls .v-radio > .v-label {
//   left: 8px !important;
// }

// Wird das überhaupt erkannt?
:not(:root):fullscreen .video-controls {
  z-index: 99999999999;
  position: absolute;
  padding-left: 50px;
}

.animated-gradient-dBlue-red-radial {
  background: rgb(0, 0, 0);
  /* background: -moz-radial-gradient(circle, rgba(0,0,0,1) 0%, rgba(0,31,255,1) 100%);
  background: -webkit-radial-gradient(circle, rgba(0,0,0,1) 0%, rgba(0,31,255,1) 100%);
  background: radial-gradient(circle, rgba(0,0,0,1) 0%, rgba(0,31,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#001fff",GradientType=1); */
  background: linear-gradient(132deg, rgb(30, 144, 255) 0.00%, rgb(138, 43, 226) 100.0%);
  z-index: 0;
  background-size: 400% 400%;
  -webkit-animation: animated-gradient-darkBlue-black 21s ease infinite;
  -moz-animation: animated-gradient-darkBlue-black 21s ease infinite;
  animation: animated-gradient-darkBlue-black 10s ease infinite;
}

@-webkit-keyframes animated-gradient-darkBlue-black {
  0% {
    background-position: 100% 0%
  }

  50% {
    background-position: 0% 100%
  }

  100% {
    background-position: 100% 0%
  }
}

@-moz-keyframes animated-gradient-darkBlue-black {
  0% {
    background-position: 100% 0%
  }

  50% {
    background-position: 0% 100%
  }

  100% {
    background-position: 100% 0%
  }
}

@keyframes animated-gradient-darkBlue-black {
  0% {
    background-position: 100% 0%
  }

  50% {
    background-position: 0% 100%
  }

  100% {
    background-position: 100% 0%
  }
}
</style>
