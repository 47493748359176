<template>
	<modal name="payoutForm">
		<!-- Headline -->
		<div class="pa-6">

			<span class="headline">Auszahlung</span><br>

			<v-card outlined class="mt-4 mb-6 pa-4 d-flex justify-center text-center">
				
				<div>
					<div class="subtitle font-weight-medium grey--text">Guthaben</div>
					<div class="primary--text text-h3 my-4">{{ sumGuthaben() }}</div>
					<div class="caption grey--text ">Stand: {{ formDate(new Date())}}</div>
				</div>
				
			</v-card>
			<div>
				<div class="title grey--text">Kosten</div>
				<v-divider class="mb-4"></v-divider>
				<v-row>
					<v-col>
						<div class="caption">Provision</div>
						<div class="">In Höhe von: 20%</div>
					</v-col>
					<v-col class="d-flex justify-end align-center">
						<div class="body-1">
							{{ sumGuthaben() / 100 * 20 }}
						</div>
					</v-col>
				</v-row>
				<!-- <v-row>
					<v-col>
						<div class="caption">Transaktionsgebühren</div>
						<div>{{countTransactions()}}x 0,50€</div>
					</v-col>
					<v-col class="d-flex justify-end align-center">
						<div class="body-1">
							{{ calcTransactionsCost() }}
						</div>
					</v-col>
				</v-row> -->
				<!-- <v-divider class="my-4"></v-divider> -->
				<div class="title grey--text mt-4">Auszahlungsbetrag</div>
				<v-divider class="mb-4"></v-divider>
				<v-row>
					<v-col>
						<div class="">Mögliche Auszahlung:</div>
					</v-col>
					<v-col class="d-flex justify-end align-center">
						<div class="body-1 font-weight-bold">
							{{ sumGuthaben()- (sumGuthaben() /100 * 20) - calcTransactionsCost()   }}
						</div>
					</v-col>
				</v-row>

				<v-form 
				v-on:submit.prevent @submit="sendPayout" 
				>
					<v-row >
						<v-col>
							<div class="mt-2">Gewünschter Auszahlungsbetrag:</div>
						</v-col>
						<v-col class="d-flex justify-end">
							<v-text-field
								label="Welcher Betrag soll ausgezahlt werden?"
								type="text"
								pattern="[0-9]*"
								inputMode="numeric"
								name="amount"
								placeholder="5,99 €"
								outlined
								dense
							></v-text-field>
						</v-col>
					</v-row>
					<div class="title grey--text mb-2">Auszahlungsart</div>
					<v-row>
						<v-col cols="6">
							<div class="mb-4">
								<v-btn @click="tooglePaypalField" outlined class="mr-2">Paypal</v-btn>
							</div>
							<div v-if="ppInfo">
								<v-text-field
									label="Paypal E-Mailadresse"
									type="email"
									name="email"
									placeholder="john@doe.com"
									outlined
								/>
							</div>
							
						</v-col>
						<v-col cols="6">
							<div class="mb-4">
								<v-btn @click="toogleBankField" outlined>Bankkonto</v-btn>
							</div>
							<div v-if="bankInfo">
								<v-text-field
									label="Name des Kontoinhabers"
									type="text"
									name="name"
									placeholder="John Doe"
									outlined
								/>
								<v-text-field
									label="IBAN"
									type="text"
									pattern="[0-9]*"
									name="iban"
									placeholder="DE01234567890123456789"
									outlined
								/>
								<v-text-field
									label="BIC"
									type="text"
									pattern="[0-9]*"
									name="bic"
									placeholder="NORSDEXXX"
									outlined
								/>
								<v-text-field
									label="Name der Bank"
									type="text"
									name="bank"
									placeholder="Deutsche Bank"
									outlined
								/>
							</div>
						</v-col>
					</v-row>

					<v-divider class="my-4"></v-divider>
					<v-row justify="end" class="mb-4 mt-6 pr-2">
						<v-btn class="mr-2" text @click="close('payoutForm')">Abbrechen</v-btn>
						<v-btn type="submit" outlined color="green"> Speichern</v-btn>
					</v-row>
				</v-form>

			</div>
		</div>
	</modal>
</template>

<script>
	import moment from 'moment';
	import APIService from '@/services/api.service';

	export default {
		name: 'payoutForm',

		components: {
			Modal: () => import('@/components/modal/modal')
		},

		data(){
			return {
				ppInfo: false,
				bankInfo: false,
				name: '',
				description: '',
				price: '',
				trial: '',
				activePlan: true,
				trialSelection: false,
				extraSelection: false,
				paymentCircle: ['Monatlich', 'Jährlich'], // Monatlich oder Jährlich,
				trialVariation: ['Kein Testzeitraum', 'Woche', 'Monat'], // Monatlich oder Jährlich,
				planNameRules: [
					v => !!v || 'Bitte Namen vergeben',
					v => (v && v.length <= 30) || 'Der Name darf nicht mehr als 30 Zeichen haben.',
					v => (v && v.length >= 2) || 'Der Name muss mindestens 2 Zeichen haben.'
				],
				planDescriptionRules: [
					v => !!v || 'Bitte eine Beschreibung eingeben',
					v => (v && v.length <= 500) || 'Die Beschreibung darf nicht mehr als 30 Zeichen haben.',
					v => (v && v.length >= 2) || 'Der Name muss mindestens 2 Zeichen haben.'
				],
				priceRules: [
					v => !!v || 'Preis darf nicht leer sein',
					v => !isNaN(parseFloat(v)) && !isNaN(v - 0) || 'Preis darf nur Zahlen und Komma enthalten',
					v => v >= 0 || 'Preis darf nicht negativ sein'
				],
				loading: false,
				valid: false
			}
		},

		methods: {
			sendPayout(e){
				this.loading = true
				var payload = APIService.getFormData(APIService.getForm(e))

				if( this.ppInfo ){
					payload.type = 'Paypal'
				}
				else{
					payload.type = 'Bank'

				}

				this.$store.dispatch('post',
					{
						endpoint: `/api/v1/payouts`,
						event: payload,
					}
				)
			},

			tooglePaypalField() {
				this.bankInfo = !this.bankInfo
				this.ppInfo = !this.ppInfo
				if (this.bankInfo) {
					this.bankInfo = !this.bankInfo	
				} 
			},
			
			toogleBankField() {
				this.bankInfo = !this.bankInfo
				if (this.ppInfo) {
					this.ppInfo = !this.ppInfo
				} 
			},

			countTransactions() {
				const orders = this.$store.state.main?.orders?.count || []
				const subs = this.$store.state?.main?.subscriptions?.count || []
				var amount = orders + subs
				return amount
			},

			calcTransactionsCost() {
				const transActions = this.countTransactions()
				var transActionsAmount = transActions * 0.5
				return transActionsAmount 
			},

			// Es werden aktuell alle Bestellungen und Subscription zusammengerechnet. 
			// Es sollte einen seperaten Endpunkt geben, welcher Auszahlungen berücksichtigt
			sumGuthaben() {
				const orders = this.sumOrders()
				const subs = this.sumSubs()
				var amount = parseFloat(orders) + parseFloat(subs)
				return amount.toFixed(2)
			},

			sumOrders() {
				const orders = this.$store.state?.main?.orders?.result || [];
				let price = 0;

				orders.forEach((order) => {
					if (order.amount !== undefined) {
					price += order.amount;
					}
				});

				return price.toFixed(2);
				},

				// sumSubs(){
				//   const plans = this.$store.state.main?.subscriptions.result
				//   var price = 0

				//   plans.forEach((subscription) => {
				//     if (subscription.plan != undefined) {
				//       price += subscription.plan.price
				//     }
				//   })
				//   return price.toFixed(2)
				// },
				sumSubs() {
				const plans = this.$store.state?.main?.subscriptions?.result || [];
				let price = 0;

				plans.forEach((subscription) => {
					if (subscription.plan !== undefined) {
					price += subscription.plan.price;
					}
				});

				return price.toFixed(2);
				},
			currentUser() {
				return this.$store.state.auth.status.loggedIn;
			},

			clearTrialSelection(){
				this.trial = '';
			},

			changePaymentCircle(){
				if (this.paymentCircle == 'Jährlich') {
					this.paymentCircle = 'Monatlich'
				}
				else {
					this.paymentCircle = 'Monatlich'
				}
			},

			formDate(value){
				if (value) {
					return moment(String(value)).format('DD.MM.YYYY')
				}
			},

			close(name) {
				this.$store.dispatch("modal/close", name)
			}
		}
		// Ende Methods
	}
</script>
