import * as components from './src/components'
import AppService from '@/services/app.service';
import router from '@/router';

export default {
  // called by Vue.use(FirstPlugin)
  install(Vue) {
  Vue.use(router)
  
    // register the component with vue
    Object.values(components).forEach(component => {
      Vue.component(component.name, component)
    })

    // Flag um sicher zu sein, dass die Routes nur einmal hinzugefügt werden
    // create a mixin
    Vue.mixin({
      beforeCreate () {
        AppService.addRoutes(this,[
          {
            path: '/gallery',
            name: 'adminGallery',
            component: 'liox-mediathek-gallery/src/views/galleries',
            beforeEnter: router.guard,
            meta: {
              roles: ['ROLE_TENANT'],
              product: 'Starter'
            },
          },
          {
            path: '/gallery/:id/image-overview',
            name: 'adminGalleryImages',
            component: 'liox-mediathek-gallery/src/views/galleryImages',
            beforeEnter: router.guard,
            meta: {
              roles: ['ROLE_TENANT'],
              product: 'Starter'
            },
          },
          {
            path: '/image-overview',
            name: 'image-overview',
            component: 'liox-mediathek-gallery/src/views/images',
            beforeEnter: router.guard,
            meta: {
              roles: ['ROLE_TENANT'],
              product: 'Starter'
            },
          },
          {
            path: '/image-upload',
            name: 'image-upload',
            component: 'liox-mediathek-gallery/src/views/image-upload',
            beforeEnter: router.guard,
            meta: {
              roles: ['ROLE_TENANT'],
              product: 'Starter'
            },
          },
          // CONSUMER SITES
          {
            path: '/galleries',
            name: 'all-galleries',
            component: 'liox-mediathek-gallery/src/views/consumer/allGalleries',
            beforeEnter: router.guard,
            meta: {
              roles: ['ROLE_TENANT', 'ROLE_END_USER'],
              product: 'Starter'
            },
          },
          {
            path: '/gallery/:id/images',
            name: 'gallery-images',
            component: 'liox-mediathek-gallery/src/views/consumer/galleryImages',
            beforeEnter: router.guard,
            meta: {
              roles: ['ROLE_TENANT', 'ROLE_END_USER'],
              product: 'Starter'
            },
          },
          {
            path: '/images',
            name: 'images',
            component: 'liox-mediathek-gallery/src/views/consumer/allImages',
            beforeEnter: router.guard,
            meta: {
              roles: ['ROLE_TENANT', 'ROLE_END_USER'],
              product: 'Starter'
            },
          },
        ]);
      },
      
      created() {
        
        if (this.menuItems) {
          AppService.addContentLinks(this,[
            {
              path: '/gallery',
              name: 'Galerien',
              icon: 'mdi-image-multiple-outline',
              beforeEnter: router.guard,
              meta: {
                roles: ['ROLE_TENANT', 'ROLE_END_USER'],
                product: 'Starter'
              },
              index: 3
            },
            {
              path: '/image-overview',
              name: 'Bilder',
              icon: 'mdi-image',
              beforeEnter: router.guard,
              meta: {
                roles: ['ROLE_TENANT', 'ROLE_END_USER'],
                product: 'Starter'
              },
              index: 4
            },
          ])
        }

        //Consumer Links
        if (this.consumerLinks) {
          AppService.addConsumerLinks(this, [
            { 
              path: 'galleries',
              name: 'Galerien',
              meta: {
                roles: ['ROLE_END_USER'],
                product: 'Starter'
              },
            },
            { 
              path: 'images',
              name: 'Bilder',
              meta: {
                roles: ['ROLE_END_USER'],
                product: 'Starter'
              },
            },
          ]);
        }

        // BITTE BEACHTEN DIRTY FIX
        // Wenn doppelte Komponenten angezeigt werden, muss diese Funktion genutzt werden 
        AppService.extendCoreComponents([
          'components/consumer/gallerySlider',
        ])
      }
    });
  },
};

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(ComponentLibrary)
}
