<template>
	<modal name="kontoLoeschen">
		<div class="pa-12">
			<p class="title">Möchtest du deinen Account wirklich löschen?</p>
			<p class="font-weight-bold ">Du verlierst damit...</p>
			<ul class="pb-4" type="circle">
				<li>Zugang zur Plattform, neuen Inhalten und mehr Training</li>
				<li>Zugang zu all deinen gekauften Videos und Inhalten</li>
				<li>Zugang zum persönlichen Whatsapp-Support</li>
			</ul>
			<p class="font-weight-bold red--text">Dieser Vorgang kann nicht rückgängig gemacht werden!</p>
			<div class="row pt-8 justify-end">
				<v-btn outlined class="mr-8" @click="close('kontoLoeschen')">abbrechen</v-btn>
				<v-btn class="error" @click="deleteAccount()">Account löschen</v-btn>
			</div>
		</div>
	</modal>
</template>

<script>
	// import Modal from "./modal"
	import {mapGetters} from 'vuex'
	import APIService from '@/services/api.service';

	export default {
		name: 'kontoLoeschen',

		components: {
			Modal: () => import('./modal'),
	  },

	  methods: {
	    currentUser() {
	      return this.$store.state.auth.status.loggedIn;
	    },

	    deleteAccount(){
	      this.$store.dispatch('delete',
	      {
					host: 'getAuthHost',
	        endpoint: `/api/v1/users/${this.$store.state.main.user.result.subject}`,
					message: false
	      })
				APIService.setResponse({message: `Das Profil wurde gelöscht`, color: "green"});

	      this.$store.dispatch('delete',
	      {
	        endpoint: `/api/v1/users/${this.$store.state.main.user.result.id}`,
					message: false
	      })

				APIService.logout()
	    },

			close(name) {
				this.$store.dispatch("modal/close", name)
			}
	  }
	}
</script>
