<template>
	<modal name="planBearbeiten">
		<div class="pa-6">

			<span class="headline primary--text">Plan bearbeiten</span>
			<v-form 
				v-on:submit.prevent 
				@submit="savePlanChange($event, get('plan').id)" 
				v-model="valid"
			>
			<!-- Titel des Plan -->
				<v-row class="mt-2">
					<v-col cols="4">
						<span class="body-1">Titel des Plan:</span>
					</v-col>
					<v-col cols="8">
						<v-text-field
							label="Titel des Plan"
							v-model="get('plan').name"
							name="name"
							placeholder="Plan Titel"
							:rules="planTitleRules"
							outlined
							required
							dense
						/>
					</v-col>
				</v-row>

				<!-- Beschreibung -->
				<v-row class="mt-2">
					<v-col cols="4">
						<span class="body-1">Inhalte:</span>
					</v-col>
					<v-col cols="8">
						<v-textarea
							v-model="get('plan').description"
							name="description"
							outlined
							:rules="planDescriptionRules"
						></v-textarea>
					</v-col>
				</v-row>

				<!-- Preis des Plans -->
				<v-row>
					<v-col cols="4">
						<span class="body-1">Preis des Plans:</span>
						<p class="caption mt- grey--text">Lege einen Preis für den Plan fest. <br>0€ = Kostenlos</p>
					</v-col>
					<v-col cols="8">
						<v-text-field
							label="Preis des Plans"
							v-model="get('plan').price"
							type="number"
							min="0"
							inputMode="decimal"
							name="price"
							placeholder="5,99 €"
							:rules="priceRules"
							outlined
							required
						></v-text-field>
					</v-col>
				</v-row>
				
				<v-divider class="my-6"></v-divider>
				<!-- Sichtbarkeit -->
				<span class="title my-2">Sichtbarkeit</span>
				<v-row class="mt-2">
					<v-col class="mb-n2">
						<span class="body-1">Plan aktivieren?</span>
						<span class="fas fa-check green--text pl-4" v-if="get('plan').active == true"></span>
						<p class="caption grey--text mr-12">Der Plan kann abgeschlossen werden.</p>
					</v-col>
					<span class="caption pt-5 pr-4">nein / ja</span>
					<v-switch
						name="active"
						v-model="get('plan').active"
						class="mr-2"
					></v-switch>
					<!-- @change="autoSaveVideoChange(get('video').id, get('video'))" -->
				</v-row>
			
				<v-divider class="my-6"></v-divider>
				
				<v-card-actions class="mb-2 mr-1">
					<v-row class="justify-end">
						<v-btn text class="grey--text" @click="close('planBearbeiten')">abbrechen</v-btn>
						<v-btn class="green--text ml-4" outlined type="submit">Speichern</v-btn>
					</v-row>
				</v-card-actions>
			</v-form>
		</div>
	</modal>
</template>

<script>

import APIService from '@/services/api.service';
import {mapGetters} from 'vuex';
export default {
	name: 'planBearbeiten',
	components: {
		Modal: () => import('@/components/modal/modal')
	},
	data(){
		return {
			plan: null,
			activePlan: null,
			valid: false, 
			priceRules: [
				v => !!v || 'Preis darf nicht leer sein',
				v => !isNaN(parseFloat(v)) && !isNaN(v - 0) || 'Preis darf nur Zahlen und Komma enthalten',
				v => v >= 0 || 'Preis darf nicht negativ sein'
			],
			planTitleRules: [
				v => !!v || 'Bitte Titel vergeben',
				v => (v && v.length <= 52) || 'Der Titel darf nicht mehr als 30 Zeichen haben.',
				v => (v && v.length >= 2) || 'Der Titel muss mindestens 2 Zeichen haben.'
			],
			planDescriptionRules: [
				v => !!v || 'Bitte eine Beschreibung eingeben',
				v => (v && v.length <= 350) || 'Die Beschreibung darf nicht mehr als 350 Zeichen haben.',
				v => (v && v.length >= 2) || 'Die Beschreibung muss mindestens 2 Zeichen lang sein.'
			],
			selectedOption: null,
			selectedPricingOption: Boolean,
		}
	},

	computed:{
	...mapGetters(["get"]),
	},

	methods: {

		itemType(){
			// this.selectedItem = payload.name
			this.selectedPricingOption != this.selectedPricingOption
			console.log("selected item:", this.selectedPricingOption)
		},

		toggleRadio(option) {
			if (this.selectedOption === option) {
				// Wenn dieselbe Option erneut ausgewählt wird, setze sie auf null zurück
				this.selectedOption = null;
			} else {
				this.selectedOption = option;
				// Radiobutton basierend auf dem Ref umschalten
				this.$refs['radio' + option][0].toggle();
			}
		},

		thumbnailUpload(id) {
			setTimeout(() => {
				this.$store.dispatch('uploadFiles',
					{
						endpoint: `/api/v1/videos/${id}/upload`,
						files: { image: this.image },
						metadata: { width: 100, height: 100 },
						message: false
					}
				).then((response) => {
					this.resetForm()
					APIService.setResponse({ message: `Deine Änderungen wurde gespeichert.`, color: "blue" });
				}).catch((error) =>{
					APIService.setResponse({ message: ``, color: "red" });
				})
			}, 100);
		},

		shareItem(item, style = 'height="500px" width="100%'){
			const src = `${window.location.protocol}//${window.location.host}/videos/${item.id}`;
			let iframe = '';
			if(item.share){
				iframe = `<iframe ${style}" src="${src}" title="${this.$store.state.main.config.result.site_name}"></iframe>`
			}
			return iframe
		},

		shareLink(item){
			return `${window.location.protocol}//${window.location.host}/videos/${item.id}`;
		},

		publishVideo(id){
			var published = this.$store.state.main.video.result.share
			published != published
			this.$store.dispatch('patch',
				{
					endpoint: `/api/v1/videos/${id}`,
					event: { share: published },
					message: false
				}
			)
		},
		stageVideo(id){
			var staged = this.$store.state.main.video.result.published
			staged != staged
			this.$store.dispatch('patch',
				{
					endpoint: `/api/v1/videos/${id}`,
					event: { published: staged },
					message: false
				}
			)
		},

		copyCodeSnippet(item){
			// var copyText = this.shareItem(this.$store.state.main.video.result)
			navigator.clipboard.writeText(item);
			APIService.setResponse({ message: "Kopiert.", color: "blue" })
		},

		close(name) {
			this.$store.dispatch("modal/close", name)
		},

		resetForm(){
			this.image = []
			this.video = []
			this.fileRecordsForUpload = []
		},

		savePlanChange(e, id){
			const object = APIService.getFormData( APIService.getForm(e));

			this.$store.dispatch('patch',
				{
					endpoint: `/api/v1/plans/${id}`,
					event: object,
					state: 'plan',
					message: false
				}
			).then(() => {
				this.$store.dispatch('fetch', { endpoint: '/api/v1/plans', state: 'plan'});
				this.$store.dispatch('fetch', { endpoint: '/api/v1/categories', state: 'categories'});
				this.$store.dispatch("modal/close", 'planBearbeiten');
				APIService.setResponse({message: "Änderung gespeichert", color: "green"});
			}).catch((error) =>{
				const {response: {status} } = error;
			})
		}
	}, 

	mounted(){
		this.$store.dispatch('fetch', { endpoint: '/api/v1/plans', state: 'plans'});
	}
}
</script>
