<template>

  <div class="">

    <v-slide-y-transition>
      <v-card v-if="selectedVideos != 0">
        <v-toolbar dense flat>
          <span class="ml-2 primary--text">{{ selectedVideos.length }} Ausgewählt</span>

          <v-divider class="ml-8 mr-4" vertical></v-divider>

          <v-btn text @click="changePublishSelected()">Sichtbarkeit ändern</v-btn>
          <v-menu v-model="categoryChoice" offset-y transition="scale-transition" :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn text v-bind="attrs" v-on="on" @click="infiniteScroll(`/api/v1/categories?page=1`, 'categories').then((infiniteTableScroll) => {
        infiniteTableScroll.addEventListener('#category-infinite')
      })">
                Kategorie zuweisen
                <v-icon color="#00C3FF" class="ml-2">mdi-chevron-down</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-radio-group id="category-infinite" v-model="selectedCategory">
                <v-list-item v-for="categorie in get('categories')" :key="categorie.id">
                  <v-list-item-action>
                    <div class="d-flex">
                      <v-radio :value="categorie.id" :label="categorie.name" class="radio-label-text font-weight-bold">
                        <template v-slot:label>
                          <div class="ml-2">{{ categorie.name }}</div>
                        </template>
                      </v-radio>
                    </div>
                  </v-list-item-action>
                </v-list-item>
              </v-radio-group>

              <v-divider class="mb-1"></v-divider>
              <v-list-item>
                <div class="d-flex">
                  <v-btn text class="pa-0 btnNoHover" @click="openModal('neueKategorie'), categoryChoice = false">
                    + Neue Kategorie
                  </v-btn>
                  <v-btn text outlined class="ml-4" @click="addVideosToCategory()">
                    Speichern
                  </v-btn>
                </div>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-menu v-model="playlistChoice" offset-y transition="scale-transition" :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn text v-bind="attrs" v-on="on" @click="infiniteScroll(`/api/v1/playlists?limit=6`, 'playlists').then((infiniteTableScroll) => {
        infiniteTableScroll.addEventListener('#infinite-scroll')
      })">
                Playlist zuweisen
                <v-icon color="#00C3FF" class="ml-2">mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <div>
                <div style="min-height: 50px; max-height: 250px" id="infinite-scroll">
                  <v-list-item v-for="playlist in get('playlists')" :key="playlist.id">
                    <v-list-item-action>
                      <div class="d-flex">
                        <v-checkbox v-model="selectedPlaylists" :value="playlist.id" class="font-weight-bold">
                          <template v-slot:label>
                            <div class="ml-2">{{ playlist.name }}</div>
                          </template>
                        </v-checkbox>
                      </div>
                    </v-list-item-action>
                  </v-list-item>
                </div>

                <v-divider></v-divider>

                <v-list-item>
                  <div class="d-flex">
                    <v-btn text class="pa-0 btnNoHover" @click="openModal('neuePlaylist'), playlistChoice == false">+
                      Neue
                      Playlist</v-btn>
                    <v-btn text outlined class="ml-4 btnNoHover" @click="addVideosToPlaylists()">Speichern</v-btn>
                  </div>
                </v-list-item>
              </div>
            </v-list>
          </v-menu>
          <v-btn text @click="deleteVideos()"> Löschen<v-icon size="21px" color="#00C3FF"
              class="pl-2">mdi-delete-outline</v-icon></v-btn>
        </v-toolbar>
      </v-card>
    </v-slide-y-transition>

    <v-card class="mt-4">
      <v-row class="my-1 ml-2">
        <v-col cols="3">
          <div class="d-flex">
            <div class="pt-1 pr-2">
              <v-icon color="grey" class="md-32 pt-1">mdi-magnify</v-icon>
            </div>
            <v-text-field v-model="search" class="FilterSearch" append-inner-icon="mdi-magnify" label="Suche nach Titel"
              @change="searchVal" dense></v-text-field>
          </div>
        </v-col>

        <v-col cols="3" class="">
          <div class="d-flex ">
            <div class="pt-1 pr-2">
              <v-icon color="grey" class="md-32 pt-1">mdi-calendar-filter</v-icon>
            </div>
            <rangeDatePicker @input="date = $event" @clicked="saveFilters" @deleted="deleteFilters"></rangeDatePicker>
          </div>
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <div>
        <v-data-table :headers="headers" :items="get('videos')" v-model="selectedVideos" item-key="id"
          selectable-key="id" show-select height="60vh" fixed-header
        >
          <template v-slot:item.title="{ item }">

            <div class="d-flex py-4">
              <v-img :src="imageSource(item)" height="70" width="110"
                gradient="to top right, rgba(100,115,201,.13), rgba(25,32,72,.5)"
                @click="fetchVideoModal(item, 'adminVideoPlayer')" class="click_cursor">
                <div class="d-flex justify-end">
                  <p class="pa-1 caption gray--text">{{ formDuration(item.duration_string) }}</p>
                </div>
              </v-img>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ item.title | truncate('21', '...') }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.description | truncate('21', '...') }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

            </div>
          </template>

          <template v-slot:item.price="{ item }">
            <div v-if="item.subscription">
              <div v-for="(plan, index) in item.plans" :key="index">
                <v-chip outlined label small>
                  {{ plan.name }}
                </v-chip>
              </div>
            </div>
            <div v-else>
              <v-chip outlined label small>
                {{ item.price }} €
              </v-chip>
            </div>
          </template>

          <template v-slot:item.published="{ item }">

            <div v-if="item.share">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-row @click="changeShare(item.id, false)" class="ma-0 pa-0 click_cursor" v-bind="attrs" v-on="on">

                    <v-icon color="#00C3FF">mdi-eye</v-icon>
                    <span class="pl-2">Public</span>

                  </v-row>
                </template>
                <span>Das Video ist öffentlich abrufbar</span>
              </v-tooltip>
            </div>

            <div v-else>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-row @click="changeShare(item.id, true)" class="ma-0 pa-0 click_cursor" v-bind="attrs" v-on="on">
                    <v-icon color="#00C3FF">mdi-eye-off-outline</v-icon>
                    <span class="pl-2">Non Public</span>
                  </v-row>
                </template>
                <span>Das Video ist nicht öffentlich sichtbar</span>
              </v-tooltip>
            </div>

            <div v-if="item.published">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-row @click="changePublish(item.id, false)" class="ma-0 pa-0 click_cursor" v-bind="attrs" v-on="on">
                    <v-icon color="#00C3FF">mdi-theater</v-icon>
                    <span class="pl-2">On Stage</span>
                  </v-row>
                </template>
                <span>Das Video ist auf der Plattform sichtbar</span>
              </v-tooltip>
            </div>
            <div v-else="item.published">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-row @click="changePublish(item.id, true)" class="ma-0 pa-0 click_cursor" v-bind="attrs" v-on="on">
                    <v-icon color="#00C3FF">mdi-video-box-off</v-icon>
                    <span class="pl-2">Backstage</span>
                  </v-row>
                </template>
                <span>Das Video ist nicht auf der Plattform sichtbar</span>
              </v-tooltip>
            </div>
          </template>

          <template v-slot:item.categorie="{ item }">
            <v-list-item class="pa-0">
              <v-list-item-content>
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-slot:item.viewer="{ item }">
            <v-list-item two-line class="pa-0" @click="linkTo(`video/${item.id}/stats`)">
              <v-list-item-content>
                <v-list-item-title>{{ item.viewer }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="showIcon" color="#00C3FF" @click="fetchVideoModal(item, 'adminVideoPlayer')"
                  v-bind="attrs" v-on="on">
                  mdi-play-circle
                </v-icon>
              </template>
              <span>Video abspielen</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="#00C3FF" class="ml-2 showIcon" @click="editVideo(item.id, 'adminVideoBearbeiten')"
                  v-bind="attrs" v-on="on">
                  mdi-pencil
                </v-icon>
              </template>
              <span>Video bearbeiten</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="ml-2 showIcon" color="#00C3FF" @click="deleteVideo(item.id)" v-bind="attrs" v-on="on">
                  mdi-delete
                </v-icon>

              </template>
              <span>Video löschen</span>
            </v-tooltip>

          </template>
          <template v-slot:footer.append>
            <div ref="loadingTrigger" class="loading-trigger d-flex justify-center mt-8">
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </div>
          </template>

        </v-data-table>
      </div>

    </v-card>

  </div>
</template>

<script>
import videoDataTable from '@/components/video/videoDataTable';
export default {
  name: 'videoDataTable',

  extends: videoDataTable,
  
  data() {
    return {
      showTextFilter: false,
      showDateFilter: false,
      show: false,
      playlistChoice: false,
      categoryChoice: false,
      observer: null,
      selectedItem: [],
      selectedVideos: [],
      selectedPlaylists: [],
      selectedCategory: '',
      showOptions: false,
      loading: true,
      dataTableLoading: false,
      timeout: null,
      date: {
        startDate: '',
        endDate: '',
      },
      search: '',
      filters: {
        name: '',
        id: '',
        added_by: '',
        start_date: null,
        end_date: null,
        prop: '',
        range: '',
      },
      headers: [
        {
          text: 'Video',
          align: 'left',
          value: 'title',
          width: '135px',
        },
        {
          text: 'Preis/Abo',
          value: 'price',
          sortable: true,
          width: '25px',
        },
        {
          text: 'Kategorie',
          value: 'categorie.name',
          sortable: true,
          width: '105px',
        },

        {
          text: 'Sichtbarkeit',
          align: 'left',
          divider: false,
          value: 'published',
          width: '135px',
        },
        // {
        //   text: 'Aufrufe',
        //   value: 'viewer',
        //   sortable: true,
        //   width: '10px',
        // },
        // {
        //   text: 'Länge',
        //   value: 'duration',
        //   sortable: true,
        //   width: '86px',
        // },

        {
          text: 'Aktionen',
          value: 'actions',
          sortable: true,
          width: '140px',
        },
      ],
    }
  },

  mounted() {
    this.infiniteScroll().then((infiniteTableScroll) => {
      this.loading = false
      infiniteTableScroll.addEventListener('.v-data-table__wrapper')
    })
  },
}
</script>
