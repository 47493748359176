import infiniteScroll from '@/services/infiniteScroll';

export const infiniteScrollMixin = {
  data() {
    return {
      // pagination: {
      //   items: [],
      //   total: 0,
      //   limit: 20,
      // },
      dataTableLoading: false,
      observer: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.setupIntersectionObserver();
    });
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {

    setupIntersectionObserver(retryCount = 0) {
      const options = {
        root: null,
        rootMargin: '150px',
        threshold: 0,
      };

      this.observer = new IntersectionObserver((entries) => {
        const target = entries[0];
        if (target.isIntersecting && !this.dataTableLoading) {
          this.loadMore();
        }
      }, options);

      // Use $nextTick to ensure the DOM has updated
      this.$nextTick(() => {
        const loadingTrigger = this.$refs.loadingTrigger;
        if (loadingTrigger) {
          this.observer.observe(loadingTrigger);
        } else {
          // console.error('Loading trigger element not found');
          // Retry after a short delay, up to 5 times
          if (retryCount < 5) {
            setTimeout(() => this.setupIntersectionObserver(retryCount + 1), 500);
          } else {
            // console.error('Failed to find loading trigger after multiple attempts');
          }
        }
      });
    },
    async initInfiniteScroll(endpoint, state) {
      // console.log('Initializing infinite scroll for state:', state);
      infiniteScroll.init(state);
      this.dataTableLoading = true;
      try {
        const result = await this.$store.dispatch('fetch', { endpoint, state });
        
        console.log('Checking on mixin pagination:', this.pagination);
        // Initialize the pagination if it doesn't exist
        if (!this.pagination[state]) {
          this.pagination[state] = [];
          console.log('Mixin: Init Pagination empty array');
        }
        else {
          console.log('Mixin: Delete first item', this.pagination);
          this.pagination[state].shift();
          console.log('Mixin: Deleted', this.pagination);
        }
        
        // Append new items instead of replacing
        this.pagination[state] = [...this.pagination[state], ...result.result];
        this.pagination.total = result.total;
    
        // Update the store
        this.$store.commit('set', {
          state: state,
          data: {
            ...this.$store.state.main[state],
            result: this.pagination[state]
          }
        });
      } catch (error) {
        console.error('Error initializing infinite scroll:', error);
      } finally {
        this.dataTableLoading = false;
      }
    },

    async loadMore() {
      const links = this.$store.state.main[this.infiniteScrollState]._links;
      if (links && links.next && !this.dataTableLoading) {
        this.dataTableLoading = true;
        try {
          const response = await this.$store.dispatch('fetch', {
            endpoint: links.next.href,
            state: this.infiniteScrollState
          });
          
          // Use the infiniteScroll service to sync the store
          console.log("loadMore: this.Pagination", this.pagination);
          this.pagination = infiniteScroll.syncStore(this.$store, this.pagination, response.result);
          console.log("after Sync: this.Pagination", this.pagination);
    
          if (this.pagination[this.infiniteScrollState].length >= this.pagination.total) {
            this.observer.unobserve(loadingTrigger);
          }
        } catch (error) {
          console.error('Error loading more items:', error);
        } finally {
          this.dataTableLoading = false;
        }
      }
    }
  },
};