<template>
	<modal name="editGalleryModal">

		<div class="pa-6">
			<!-- Headline -->
			<span class="headline primary--text mb-4">Galerie bearbeiten</span>

			<v-form v-on:submit.prevent @submit="saveGallery($event, get('gallery').id)" v-model="valid" class="mt-4">
				
				<span class="body-1 font-weight-bold">Details</span>
				<v-row>
					<v-col cols="4" class="mt-2">
						<span class="body-1">Titel der Galerie:</span>
					</v-col>
					<v-col cols="8">
						<!-- Name der Galerie -->
						<v-text-field
							label="Titel"
							v-model="get('gallery').title"
							name="title"
							placeholder="Name der Galerie"
							:rules="galleryNameRules"
							single-line
							outlined
							clearable
							required
							counter="32"
						/>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="4" class="mt-2">
						<span class="body-1">Beschreibung:</span>
					</v-col>
					<v-col cols="8">
						<!-- Beschreibung der Galerie -->
						<v-textarea
							label="Beschreibung"
							v-model="get('gallery').description"
							name="description"
							placeholder="Beschreibung der Galerie"
							:rules="galleryDescriptionRules"
							single-line
							outlined
							counter="2000"
						/>
					</v-col>
				</v-row>
				
				<v-divider class="my-6 mr-2"></v-divider>

				<!-- Im Abo enthalten? -->
				<span class="body-1 font-weight-bold mt-2">Preis</span>
				<v-row>
					<v-col cols="4" class="mt-2">
						<span class="body-1">Galerie im Abo enthalten:</span>
						<span class="fas fa-check green--text pl-4" v-if="get('gallery').subscription == true"></span>
						<p class="caption mt- grey--text">Ist dieses Video für alle Abonnenten verfügbar?</p>
					</v-col>
					<v-col cols="8">
						<div class="d-flex justify-end">
							<span class="mr-4 caption">nein / ja</span>
							<v-switch
								v-model="get('gallery').subscription"
								name="subscription"
								class="ma-0 pa-0"
							></v-switch>
						</div>
					</v-col>
				</v-row>
				
				<!-- Preis der Galerie -->
				<!-- Nur wenn nicht im Video enthalten -->
				<v-row 
					v-if="get('gallery').subscription == false" 
				>
					<v-col cols="4">
						<span class="body-1">Preis der Galerie:</span>
						<p class="caption mt- grey--text">Lege einen Preis für die Galerie fest. <br>0€ = Kostenlos</p>
					</v-col>
					<v-col cols="8">
						<!-- type="number"
						pattern="[0-9]*"
						inputMode="numeric" -->
						<v-text-field
							label="Preis der Galerie"
							v-model="get('gallery').price"
							name="price"
							placeholder="5,99 €"
							outlined
							dense
							:rules="priceRules"
						></v-text-field>
					</v-col>
				</v-row>

				<v-row v-else class="mt-2">
					<v-col cols="4">
						<span class="body-1">Abo zuweisen:</span>
					</v-col>
					<v-col cols="8" class="px-3 ma-0">

						<v-select 
							multiple
							name="plans"
							close-text
							v-model="get('gallery').plans"
							:items="get('plans')"
							item-text="name"
							item-value="id"
							label="Auswahl eines Plans"
							:rules="[v => !!v || 'Bitte einen Plan auswählen']"
							outlined
							chips
							deletable-chips
						>
							<template v-slot:selection="{ item }">
								<v-chip
									close
									color="primary"
									@click:close="deletePlanFromGallery(item)"
									outlined
								>
									{{item.name}}
								</v-chip>
							</template>
						</v-select>

					</v-col>
				</v-row>
				
				<v-divider class="my-4"></v-divider>
				
				<!-- On Stage? -->
				<!-- Soll das Video auf der Plattform sichtbar sein? -->
				<span class="body-1 font-weight-bold my-2">Sichtbarkeit</span>
				<div class="mt-n4 pa-0 d-flex justify-space-between align-center">
					<div>
						<span class="body-1">Galerie veröffentlichen?</span>
					</div>
					<div class="d-flex align-center">
						<span class="pr-4 grey--text">nein / ja</span>
						<v-switch
							v-model="get('gallery').published"
							name="published"
							class=""
						></v-switch>
					</div>
				</div>

				<v-divider class="my-4"></v-divider>
				
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text gray--text @click="close('editGalleryModal')">abbrechen</v-btn>
					<v-btn 
						type="submit" 
						color="green darken-1" 
						:disabled="!valid" 
						:loading="loading"
						outlined 
						dark 
					>Speichern</v-btn>
				</v-card-actions>
			</v-form>
		</div>
	</modal>
</template>

<script>
	import APIService from '@/services/api.service';
	import { mapGetters } from 'vuex';
	import AppService from '@/services/app.service';

	export default {
		name: 'editGalleryModal',

		components: {
			Modal: () => import('@/components/modal/modal')
		},

		data(){
			return {
				// title: '',
				// description: '',
				// price: '',
				plan: null,
				published: false,
				loading: false,
				valid: false,
				galleryNameRules: [
					v => !!v || 'Bitte Namen vergeben',
					v => (v && v.length <= 32) || 'Der Name darf nicht mehr als 30 Zeichen haben.',
					v => (v && v.length >= 2) || 'Der Name muss mindestens 2 Zeichen haben.'
				],
				galleryDescriptionRules: [
					v => !!v || 'Bitte Namen vergeben',
					v => (v && v.length <= 2000) || 'Die Beschreibung darf nicht mehr als 2000 Zeichen haben.',
					v => (v && v.length >= 2) || 'Der Name muss mindestens 2 Zeichen haben.'
				],
				priceRules: [
					v => !isNaN(v - 0) || 'Preis darf nur Zahlen und Komma enthalten',
					v => v >= 0 || 'Preis darf nicht negativ sein'
				],


			}
		},

		computed:{
			...mapGetters(["get"]),
		},

		methods: {

			deletePlanFromGallery(item){
				this.$store.dispatch('delete', { endpoint: `/api/v1/galleries/${this.$store.state.main.gallery.result.id}/plans/${item.id}`})
				.then(() => {
					this.$store.dispatch('fetch', { endpoint: `/api/v1/galleries/${this.$store.state.main.gallery.result.id}`, state: 'gallery'})
					// this.$store.dispatch('fetch', { endpoint: `/api/v1/galleries?sort=DESC`, state: 'gallery'});
				})
			},
				
			saveGallery(e, id){
				this.loading = true;
				let object = APIService.getFormData( APIService.getForm(e));
				object = AppService.formatPlans(object, 'gallery');

				this.$store.dispatch('patch',
					{
						endpoint: `/api/v1/galleries/${id}`,
						state: 'gallery',
						event: object,
						message: false
					}
				)
				.then(() => {
					this.$store.dispatch('fetch', { endpoint: `/api/v1/galleries/${id}`, state: 'gallery'})
					this.$store.dispatch('fetch', { endpoint: '/api/v1/galleries?sort=DESC', state: 'galleries'});

					APIService.setResponse({ message: "Die Galerie wurde geändert.", color: "green" })
					this.loading = false
					this.$store.dispatch("modal/close", 'editGalleryModal')
				}).catch((error) => {
					this.loading = false
				})
			},

			close(name) {
				this.$store.dispatch("modal/close", name)
			}
		}
		// Ende Methods
	}
</script>
