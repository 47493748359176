import Hls from 'hls.js';
import APIService from '@/services/api.service';

class videoPlayerService {

	// Video HTML Element
	video;

	// HLS Video Player
	player;

	// HLS qualities
	quality;

	seek;

	constructor() { };

	// Initialisierung des Videoplayers:
	// id = HTML-Element ID;
	// comp = Videoplayer Komponente,
	// live = Livestream
	init(id, comp, live = false) {
		return new Promise((resolve) => {
			this.video = document.getElementById(id);

			// If no native HLS support, check if HLS.js is supported
			// if (this.video.canPlayType('application/vnd.apple.mpegurl')) {
			if (!live) {
				if (Hls.isSupported()) {
					let token = JSON.parse(localStorage.getItem('token'));
					config = {
						xhrSetup(xhr, url) {
							if (url.indexOf('/key') > 0) {
								xhr.setRequestHeader('Authorization', 'Bearer ' + token['access_token']);
							}
						},
						onError: (event, data) => {
							if (data.type === Hls.ErrorTypes.KEY_SYSTEM_ERROR) {
								APIService.setResponse({ message: "Fehler beim Laden des Schlüssels. Bitte versuchen Sie es später erneut." });
								return;
							}
						}
					};
					if (comp.$store.state.main.video.result.share) {
						var config = {};
					}
					this.player = new Hls(config)
					this.loadSource(comp.playlist);

				} else if (this.video.canPlayType('application/vnd.apple.mpegurl')) {
					this.video.src = comp.playlist;
				}
			}
			this.initEventListeners(comp)
			return resolve(this)
		})
	}

	// Es wird eine URL als Source übergeben und an den HLS Player weitergereicht
	// Anschließend wird das Video an den Player "attached"
	// und das Video schließlich abgespielt.
	loadSource(source) {
		const progressBar = document.getElementById('progress-bar');
		let value = 0;

		this.player.on(Hls.Events.ERROR, function (event, data) {
			if (data.fatal) {
				switch (data.type) {
					case Hls.ErrorTypes.NETWORK_ERROR:
						APIService.setResponse({ message: "Netzwerkfehler. Bitte versuche es später erneut." });
						break;
					case Hls.ErrorTypes.MEDIA_ERROR:
						APIService.setResponse({ message: "Media Fehler. Bitte versuche es später erneut." });
						break;
					default:
						APIService.setResponse({ message: "Kann nicht geladen werden. Wir kümmern uns." });
						break;
				}
				if (this.player) {
					this.player.destroy();
				}
				return;
			}
			if (!data.fatal && data.details === 'keyLoadError') {
				APIService.setResponse({ message: "Key kann nicht geladen werden." });
				console.log("Fehler | videoPlayerService.loadSource", data);
				if (this.player) {
					this.player.destroy();
				}
				return;
			}
		});

		this.player.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
			if (!this.quality) {
				this.quality = data.levels;
			}
			// HLS eigene loadSource Funktion
			this.player.loadSource(source);
			this.player.attachMedia(this.video);
		});

		this.player.on(Hls.Events.FRAG_BUFFERED, function (event, data) {
			value += data.frag.duration * 1.5;
			progressBar.value = value;
		});
	}

	//Duration aus secunden
	getDuration(timeInSeconds) {

		let mins = (timeInSeconds / 60)
		let hours = Math.floor(mins / 60);
		let seconds = (mins - Math.floor(mins)) * 60;

		mins = Math.floor(mins - hours * 60)
		seconds = Math.round(seconds)
		hours = hours < 1 ? '' : hours + ':';
		seconds = seconds < 10 ? '0' + seconds : seconds;

		return `${hours}${mins}:${seconds}`;

	}

	// ###############
	// Fetch Video and open Modal

	fetchVideoModal(video, name, comp) {
		comp.$store.dispatch('fetch', { endpoint: `/api/v1/videos/${video.id}`, state: 'video' })
			.then(() => {
				comp.$store.dispatch("modal/open", name)
			})
	}

	// ###############
	// Video Start
	// ###############

	startPlay(comp) {
		const playPauseBtnCenter = document.getElementById('play-pause-btn-center');

		const playPromise = this.video.play();
		console.log("playPromise", playPromise)

		if (playPromise !== undefined) {
			playPromise.then(() => {
				// Automatic playback started!
				if (playPauseBtnCenter) {
					playPauseBtnCenter.classList.add('blendOut');
				}
				comp.play = false;
				comp.playerLoading = false;
				comp.ppBtn = true;
				comp.replayBtn = false;
				return playPromise
			})
			.catch(error => {
				// Auto-play was prevented

				comp.playerLoading = false;
				comp.ppBtn = false; // Ensure play button is visible
				// You might want to show a message to the user or trigger a UI update
				// comp.$emit('autoplayFailed') // If you want to handle this in the parent component
			});
		} else {
			// For browsers that don't return a promise (unlikely in modern browsers)
			if (playPauseBtnCenter) {
				playPauseBtnCenter.classList.add('blendOut');
			}
			comp.play = false;
			comp.playerLoading = false;
			comp.ppBtn = true;
			comp.replayBtn = false;
		}

		return playPromise; // Return the promise for further handling if needed
	}


	// ###############
	// Video Pausiert
	// ###############

	pausePlay(comp) {
		const playPauseBtnCenter = document.getElementById('play-pause-btn-center')

		if (playPauseBtnCenter) {
			this.video.pause();
			comp.play = true;
			comp.ppBtn = false;
			playPauseBtnCenter.classList.remove('blendOut')
			playPauseBtnCenter.style.opacity = 1;
		}
	}


	initEventListeners(comp) {

		this.seek = document.getElementById('seek');
		const progressBar = document.getElementById('progress-bar');
		const seekTooltip = document.getElementById('seek-tooltip');

		if (this.seek && progressBar && seekTooltip) {

			// Einfacher Klick ins Video zum pausieren und wieder abspielen des Videos
			this.clickHandler = () => {
				if (comp.play) {
					this.video.play(comp)
				} else {
					// this.video.pause(comp)
					this.pausePlay(comp)
				}
			};

			// Doppelklick für Fullscreen
			this.dblclickHandler = () => {
				console.log("clickHandler");
				if (comp.fullscreen) {
					comp.exitFullScreen();
					comp.fullscreen = true;
				} else {
					comp.triggerFullScreen();
					comp.fullscreen = false;
				}
			};

			this.fullscreenChangeHandler = () => {
				var full_screen_element = document.fullscreenElement;
				if (full_screen_element !== null) {
					comp.fsBtn = false
					comp.fullscreen = true
				} else {
					comp.fsBtn = true
					comp.fullscreen = false
				}
			}

			// Wenn das Video beendet wurde den Button wieder auf Play setzten
			this.endedHandler = () => {
				comp.replayBtn = true;
				comp.ended();
			};

			// An welcher Stelle ist das Video gerade
			this.timeupdateHandler = () => {
				comp.videoTime.duration = this.getDuration(this.video.duration)
				comp.videoTime.current = this.getDuration(this.video.currentTime)
				progressBar.setAttribute('max', Math.floor(this.video.duration));
				this.seek.setAttribute('max', Math.floor(this.video.duration));
				this.seek.value = Math.floor(this.video.currentTime);
			}

			this.loadeddataHandler = () => {
				if (this.video.readyState >= 2) {
					comp.playerLoading = false;
				}
			}

			this.mousemoveHandler = (e) => {
				seekTooltip.style.opacity = 0;
			}

			this.changeHandler = (e) => {
				comp.ppBtn = true;
				comp.videoSeeking(e.target.value);
			}

			this.inputHandler = (e) => {
				comp.videoSeeking(e.target.value);
				comp.ppBtn = true;
			}

			this.seekMousemoveHandler = (e) => {
				const skipTo = Math.round((e.offsetX / e.target.clientWidth) * parseInt(e.target.getAttribute('max'), 10));
				this.seek.setAttribute('data-seek', skipTo)
				seekTooltip.textContent = this.getDuration(skipTo);
				const rect = this.video.getBoundingClientRect();
				seekTooltip.style.opacity = 1;
				seekTooltip.style.left = `${e.pageX - rect.left}px`;
			}

			this.shortcutsHandler = (e) => {
				const { key } = e;
				const activeElement = document.activeElement;
				const isInputActive = activeElement.tagName.toLowerCase() === 'input';

				if (!isInputActive) {
					switch (key) {
						case 'strg m':
							comp.muteVideo();
							break;

						case 'f':
							if (comp.fullscreen) {
								comp.exitFullScreen();
								comp.fullscreen = true;
							} else {
								comp.triggerFullScreen();
								comp.fullscreen = false;
							}
							break;
						case 'ArrowRight':
							comp.forwardVideo();
							break;
						case 'ArrowLeft':
							comp.rewindVideo();
							break;
					}
				}
			};

			this.video.addEventListener('click', this.clickHandler);
			this.video.addEventListener('dblclick', this.dblclickHandler);
			this.video.addEventListener('ended', this.endedHandler);
			this.video.addEventListener('timeupdate', this.timeupdateHandler);
			this.video.addEventListener('loadeddata', this.loadeddataHandler);
			this.video.addEventListener('mousemove', this.mousemoveHandler);
			this.seek.addEventListener('change', this.changeHandler);
			this.seek.addEventListener('input', this.inputHandler);
			this.seek.addEventListener('mousemove', this.seekMousemoveHandler);
			document.addEventListener('fullscreenchange', this.fullscreenChangeHandler)
			document.addEventListener('keydown', this.shortcutsHandler)
		}
	}

	removeEventListeners(comp) {
		if (this.video) {

			this.video.removeEventListener('click', this.clickHandler);
			this.clickHandler = null;
			this.video.removeEventListener('dblclick', this.dblclickHandler);
			this.dblclickHandler = null;
			this.video.removeEventListener('ended', this.endedHandler);
			this.endedHandler = null;
			this.video.removeEventListener('timeupdate', this.timeupdateHandler);
			this.timeupdateHandler = null;
			this.video.removeEventListener('loadeddata', this.loadeddataHandler);
			this.loadeddataHandler = null;
			this.video.removeEventListener('mousemove', this.mousemoveHandler);
			this.mousemoveHandler = null;

			this.seek.removeEventListener('change', this.changeHandler);
			this.changeHandler = null;
			this.seek.removeEventListener('input', this.inputHandler);
			this.inputHandler = null;
			this.seek.removeEventListener('mousemove', this.seekMousemoveHandler);
			this.seekMousemoveHandler = null;

			document.removeEventListener('fullscreenchange', this.fullscreenChangeHandler)
			this.fullscreenChangeHandler = null;
			document.removeEventListener('keydown', this.shortcutsHandler)
			this.shortcutsHandler = null;
		}
	}
}

export default new videoPlayerService();


