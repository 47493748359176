class InfiniteScroll {
	constructor() {
		this.state = '';
	}

	init(state) {
		this.state = state;
	}

	syncStore(store, pagination, data) {
		if (!Array.isArray(data)) {
			data = Object.values(data);
		}

		console.log('syncStore: Checking pagination:', pagination);
		// Ensure we're not replacing the initial data
		if (!pagination[this.state]) {
			console.log('Pagination ist empty');
			pagination[this.state] = [];
		}

		// Append new data to existing data
		pagination[this.state] = [...pagination[this.state], ...data];
		console.log('syncStore: Pagination after append:', pagination);
		store.state.main[this.state].result = pagination[this.state]
		console.log('syncStore: Store state:', store.state.main[this.state].result);
		return pagination;
	}
}

export default new InfiniteScroll();