
import main from '@/store/main';

class userRole {
  // getDataFromLocalStorage(){
  //   const user = JSON.parse(localStorage.getItem('user'));
  //   if (user === null || user === undefined) {
  //     return false;
  //   }
  //   return user;
  // }

  isOwner(user){
    // console.log("USER im Role Service", this.getDataFromLocalStorage());
    return user.id == main.state.user.result.id
  }

  hasRole(roles){
    let user = null
    if (localStorage.getItem('user') != 'undefined'){
      user = JSON.parse(localStorage.getItem('user'));
    }

    if (user === null || user === undefined) {
      return false;
    }
    if (roles.includes(user.role)){
      return true;
    }
    return false
  }
}

export default new userRole();
