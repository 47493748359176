import { render, staticRenderFns } from "./adminVideoBearbeiten.vue?vue&type=template&id=6164d46a"
import script from "./adminVideoBearbeiten.vue?vue&type=script&lang=js"
export * from "./adminVideoBearbeiten.vue?vue&type=script&lang=js"
import style0 from "./adminVideoBearbeiten.vue?vue&type=style&index=0&id=6164d46a&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports