<template>
	<modal name="editImageModal">
		<!-- Headline -->
		<div class="pa-6">
			<!-- Headline -->
			<span class="headline primary--text">Bild bearbeiten</span>

			<v-form v-on:submit.prevent @submit="saveImage($event, get('image').id)" v-model="valid" class="mt-4">
				<span class="body-1 font-weight-bold">Beschreibung</span>
				<v-row>
					<v-col cols="4" class="mt-2">
						<span class="body-1">Titel des Bildes:</span>
					</v-col>
					<v-col cols="8">
						<!-- Name des Bildes -->
						<v-text-field
							label="Titel"
							name="title"
							v-model="get('image').title"
							placeholder="Name des Bildes"
							outlined
							dense
							required
							autofocus
							clearable
							counter="50"
							:rules="imageNameRules"
						/>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="4" class="mt-2">
						<span class="body-1">Beschreibung des Bildes:</span>
					</v-col>
					<v-col cols="8">
						<!-- Beschreibung des Bildes -->
						<v-textarea
							label="Beschreibung"
							name="description"
							v-model="get('image').description"
							placeholder="Beschreibung zum Bild"
							outlined
							required
							autofocus
							clearable
							counter="2000"
							:rules="imgDescriptionRules"
						/>
					</v-col>
				</v-row>
				
				<!-- Altersfreigabe -->
				<v-row class="mt-2">
					<v-col cols="4">
						<span class="body-1">Altersfreigabe:</span>
					</v-col>
					<v-col cols="8">
						<v-select 
							name="fsk"
							v-model="get('video').fsk"
							:items="fskItems"
							item-text="fsk"
							item-value="id"
							label="Auswahl Altersfreigabe"
							outlined
						></v-select>
					</v-col>
				</v-row>
				
				<v-divider class="mt-2 mb-4"></v-divider>
				<!-- Im Abo enthalten? -->
				<span class="body-1 font-weight-bold mt-2">Preis</span>
				<v-row>
					<v-col cols="4" class="mt-2">
						<span class="body-1">Bild im Abo enthalten:</span>
						<span class="fas fa-check green--text pl-4" v-if="get('image').subscription == true"></span>
						<p class="caption mt- grey--text">Ist dieses Video für alle Abonnenten verfügbar?</p>
					</v-col>
					<v-col cols="8">
						<div class="d-flex justify-end">
							<span class="mr-4 caption">nein / ja</span>
							<v-switch
								v-model="get('image').subscription"
								name="subscription"
								class="ma-0 pa-0"
							></v-switch>
						</div>
					</v-col>
				</v-row>
				<v-row 
					v-if="get('image').subscription == false" 
				>
					<v-col cols="4">
						<span class="body-1">Preis des Bildes:</span>
						<p class="caption mt- grey--text">Lege einen Preis für die Galerie fest. <br>0€ = Kostenlos</p>
					</v-col>
					<v-col cols="8">
						<v-text-field
							label="Preis der Bildes"
							name="price"
							v-model="get('image').price"
							placeholder="5,99"
							outlined
							:rules="priceRules"
						/>
					</v-col>
				</v-row>
				<v-row v-else class="mt-2">
					<v-col cols="4">
						<span class="body-1">Abo zuweisen:</span>
					</v-col>
					<v-col cols="8" class="px-3 ma-0">

						<v-select 
							multiple
							name="plans"
							close-text
							v-model="get('image').plans"
							:items="get('plans')"
							item-text="name"
							item-value="id"
							label="Auswahl eines Plans"
							:rules="[v => !!v || 'Bitte einen Plan auswählen']"
							outlined
							chips
							deletable-chips
						>
							<template v-slot:selection="{ item }">
								<v-chip
									close
									color="primary"
									@click:close="deleteItem(item)"
									outlined
								>
									{{item.name}}
								</v-chip>
							</template>
						</v-select>						

					</v-col>
				</v-row>
				<v-divider class="my-4"></v-divider>
				
				<!-- On Stage? -->
				<!-- Soll das Video auf der Plattform sichtbar sein? -->
				<span class="body-1 font-weight-bold my-2">Sichtbarkeit</span>
				<div class="mt-n4 pa-0 d-flex justify-space-between align-center">
					<div>
						<span class="body-1">Bild veröffentlichen?</span>
					</div>
					<div class="d-flex align-center">
						<span class="pr-4 grey--text">nein / ja</span>
						<v-switch
							v-model="get('image').published"
							name="published"
							class=""
						></v-switch>
					</div>
				</div>
				<v-divider class="my-4"></v-divider>
				<v-card-actions class="">
					<v-spacer></v-spacer>
					<v-btn text grey--text @click="close('editImageModal')">abbrechen</v-btn>
					<v-btn 
						type="submit" 
						color="green darken-1" 
						:disabled="!valid" 
						:loading="loading"
						outlined 
						dark 
					>Speichern</v-btn>
				</v-card-actions>
			</v-form>
		</div>
	</modal>
</template>

<script>
	import APIService from '@/services/api.service';
	import { mapGetters } from 'vuex';
	import AppService from '@/services/app.service';

	export default {
		name: 'editImageModal',

		components: {
			Modal: () => import('@/components/modal/modal')
		},

		data(){
			return {
				// title: '',
				// description: '',
				galleries: '',
				plan: '',
				published: false,
				loading: false,
				valid: false,
				galleryChoiceMenu: false,
				selectedGalleries: [],
				fskItems: [
					{ id: 0, fsk: 'Ohne Altersbeschränkung' },
					{ id: 6, fsk: 'Ab 6 Jahren' },
					{ id: 12, fsk: 'Ab 12 Jahren' },
					{ id: 16, fsk: 'Ab 16 Jahren' },
					{ id: 18, fsk: 'Ab 18 Jahren' }
				],
				imageNameRules: [
					v => !!v || 'Bitte Namen vergeben',
					v => (v && v.length <= 50) || 'Der Name darf nicht mehr als 50 Zeichen haben.',
					v => (v && v.length >= 2) || 'Der Name muss mindestens 2 Zeichen haben.'
				],
				imgDescriptionRules: [
					v => !!v || 'Bitte Namen vergeben',
					v => (v && v.length <= 2000) || 'Der Name darf nicht mehr als 1000 Zeichen haben.',
					v => (v && v.length >= 2) || 'Der Name muss mindestens 2 Zeichen haben.'
				],
				priceRules: [
					v => !isNaN(v - 0) || 'Preis darf nur Zahlen und Komma enthalten',
					v => v >= 0 || 'Preis darf nicht negativ sein'
				],

			}
		},

		computed:{
			...mapGetters(["get"]),
			hasGalleryInside() {
				if(this.$store.state.main.galleries.result != undefined) {
				const galleries = this.$store.state.main.galleries.result;
				return imageId => {
					const foundGallery = galleries.find(gallery => gallery.images.some(image => image.id === imageId));
					return foundGallery ? foundGallery.title : 'Keiner Galerie zugewiesen';
				};
				}
				return 'Keiner Daten verfügbar.'
			},
		},

		methods: {
			
			deleteItem(item){
				this.$store.dispatch('delete', { endpoint: `/api/v1/images/${this.$store.state.main.image.result.id}/plans/${item.id}`})
				.then(() => {
					this.$store.dispatch('fetch', { endpoint: `/api/v1/images?sort=DESC`, state: 'image'});
				})
			},

			saveImage(e, id){
				this.loading = true;
				let object = APIService.getFormData( APIService.getForm(e));
				object = AppService.formatPlans(object, 'image');
				object.fsk = parseInt(object.fsk)
				this.$store.dispatch('patch',
				{
					endpoint: `/api/v1/images/${id}`,
					state: 'image',
					event: object,
					message: false
				}).then(() => {
					this.loading = false;
					this.$store.dispatch('fetch', { endpoint: `/api/v1/images/${id}`, state: 'image'})
					this.$store.dispatch('fetch', { endpoint: '/api/v1/images?sort=DESC', state: 'images'});

					APIService.setResponse({ message: "Das Bild wurde geändert.", color: "green" })
					this.$store.dispatch("modal/close", 'editImageModal')
				})
				
				
			},

			close(name) {
				this.$store.dispatch("modal/close", name)
			}
		}
		// Ende Methods
	}
</script>
