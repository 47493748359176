<template>
  <div>

    <component :is="isVideoCart"/>

  </div>
</template>

<script>


  import rightSiteMenu from '@/components/rightSiteMenu/rightSiteMenu';
  const VideoCart = () => import('./videoCart');

  export default {
    name: "rightSiteMenu",
    extends: rightSiteMenu,

    computed: {
      isVideoCart:{
        get(){
          if ( typeof this.$store.state.main.providers.result !== "undefined"  ) return VideoCart;
        },
        set(val){
          this.$emit("input", val)
        }
      },
    },
  }
</script>
