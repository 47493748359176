import Vue from 'vue';
import Vuetify from 'vuetify/lib';
// import minifyTheme from 'minify-css-string'
import colors from 'vuetify/lib/util/colors'
import light from './themes/light'
// import dark from './themes/dark'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify);

export default new Vuetify({
  theme: { 
    themes: {
      light
    },
  },
});
