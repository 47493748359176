import axios from 'axios';

axios.interceptors.request.use(
  (request) => {
    // loader starten 
    return request;
  }
);

axios.interceptors.response.use(
  (response) => {
    // loader stoppen 
    return response;
  }
);

export default axios;