<template>
	<div class="video-insights">
		<v-row class="ml-4 pt-3">
			<v-col class="d-flex align-center">

				<v-icon class="mr-2">mdi-eye-outline</v-icon>
				<span class="subtitle-2">{{get('video').viewer}}</span>

				<v-icon class="mr-2 ml-6">mdi-clock-outline</v-icon>
				<span class="subtitle-2">{{formDuration(get('video').duration_string)}}</span>

				<v-icon class="mr-2 ml-6">mdi-calendar</v-icon>
				<span class="subtitle-2 ">{{formDate(get('video').created)}}</span>

			</v-col>
		</v-row>

	</div>
</template>

<script>

import AppService from '@/services/app.service';
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
  name: 'videoInsights',

	data(){
		return{

		}
	},

	computed:{...mapGetters(["get"])},

	methods: {

		// Insights
		formDate(value){
		 if (value) {
			 return moment(String(value)).format('DD.MM.YYYY')
			}
		},

		formDuration(duration){
			return AppService.getDurationAsString(duration);
		},

	}

}
</script>
