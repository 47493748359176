export default function authHeader(type) {
  let token = JSON.parse(localStorage.getItem('token'));

  if (token && token[type]) {
    return { Authorization: 'Bearer ' + token[type] }; // for Spring Boot & PHP back-end
    // return { 'x-access-token': user.accessToken };       // for Node.js Express back-end
  } else {
    return {};
  }
}
