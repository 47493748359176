
class infiniteTableScroll {
    component;
    state;
    constructor() {}

    init(component, endpoint, state) {
        this.component = component;
        this.state = state;
        // this.component.loading = true;
        return new Promise((resolve) => {
            this.component.$store.dispatch('fetch', { endpoint: endpoint, state: state})
            .then(() => {
                this.component.pagination[this.state] = component.$store.state.main[this.state].result;
                this.component.pagination.total = component.$store.state.main[this.state].total;
                // this.component.loading = false;
                return resolve(this);
            })
        })
    }

    addEventListener(element) {
        const wrapper = document.querySelectorAll(`${element}`)[0];
        if( typeof wrapper !== "undefined" ){
            if (wrapper.getAttribute('listener') !== 'true') {

              wrapper.style.overflowY = 'scroll';
              wrapper.addEventListener('scroll', () => {

                if (wrapper.offsetHeight + wrapper.scrollTop >= wrapper.scrollHeight) {
                  this.scroll();
                }
              })
            }
        }

    }

    removeEventListener(element){
      const wrapper = document.querySelector(`${element}`);
      wrapper.removeEventListener('scroll', this.scroll)
    }

    scroll() {
        if (this.component.$store.state.main[this.state]._links.hasOwnProperty('next')) {
            this.component.dataTableLoading = true;
            this.component.$store.dispatch('fetch', {
                endpoint: this.component.$store.state.main[this.state]._links.next.href, state: this.state
            })
            .then ((response) => {
                this.syncStore(response.result)
                this.component.dataTableLoading = false;
            })
        }
    }

    syncStore(data) {
        if( !Array.isArray(data)  ){
            data = Object.values(data);
        }
        this.component.pagination[this.state] = [...this.component.pagination[this.state], ...data]
        this.component.$store.state.main[this.state].result = this.component.pagination[this.state]
    }

}

export default new infiniteTableScroll();
