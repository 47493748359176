<template>

  <div class="">

    <v-slide-y-transition>
      <v-card v-if="selectedVideos != 0">
        <v-toolbar
          dense
          flat
        >
          <span class="ml-2 primary--text">{{selectedVideos.length}} Ausgewählt</span>
          <v-divider
            class="ml-8 mr-4"
            vertical
          ></v-divider>

          <v-btn text @click="changePublishSelected()">Sichtbarkeit ändern</v-btn>
          <v-menu
            v-model="categoryChoice"
            offset-y
            transition="scale-transition"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                v-bind="attrs"
                v-on="on"
                @click="infiniteScroll(`/api/v1/categories?page=1`, 'categories').then((infiniteTableScroll) => {
                  infiniteTableScroll.addEventListener('#category-infinite')
                })"
              >
                Kategorie zuweisen
                <v-icon color="primary" class="ml-2">mdi-chevron-down</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-radio-group id="category-infinite" v-model="selectedCategory">
                <v-list-item v-for="categorie in get('categories')" :key="categorie.id">
                  <v-list-item-action>
                    <div class="d-flex">
                      <v-radio :value="categorie.id" :label="categorie.name" class="radio-label-text font-weight-bold">
                        <template v-slot:label>
                          <div class="ml-2">{{ categorie.name }}</div>
                        </template>
                      </v-radio>
                      <!-- <span class="ml-2 font-weight-bold">{{categorie.name}}</span> -->
                    </div>
                  </v-list-item-action>
                </v-list-item>
              </v-radio-group>

              <v-divider class="mb-1"></v-divider>
              <v-list-item>
                <div class="d-flex">
                  <v-btn text class="pa-0 btnNoHover" @click="openModal('neueKategorie'), categoryChoice = false">
                    + Neue Kategorie
                  </v-btn>
                  <v-btn text outlined class="ml-4" @click="addVideosToCategory()">
                    Speichern
                  </v-btn>
                </div>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-menu
            v-model="playlistChoice"
            offset-y
            transition="scale-transition"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn 
                text
                v-bind="attrs"
                v-on="on"
                @click="infiniteScroll(`/api/v1/playlists?limit=6`, 'playlists').then((infiniteTableScroll) => {
                  infiniteTableScroll.addEventListener('#infinite-scroll')
                })"
              >
                Playlist zuweisen
                <v-icon color="primary" class="ml-2">mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <div>
                <div style="min-height: 50px; max-height: 250px" id="infinite-scroll">
                  <v-list-item v-for="playlist in get('playlists')" :key="playlist.id">
                    <v-list-item-action>
                      <div class="d-flex">
                        <v-checkbox v-model="selectedPlaylists" :value="playlist.id" class="font-weight-bold">
                          <template v-slot:label>
                            <div class="ml-2">{{ playlist.name }}</div>
                          </template>
                        </v-checkbox>
                      </div>
                    </v-list-item-action>
                  </v-list-item>
                </div>

                <v-divider></v-divider>

                <v-list-item>
                  <div class="d-flex">
                    <v-btn text class="pa-0 btnNoHover" @click="openModal('neuePlaylist'), playlistChoice == false">+ Neue Playlist</v-btn>
                    <v-btn text outlined class="ml-4 btnNoHover" @click="addVideosToPlaylists()">Speichern</v-btn>
                  </div>
                </v-list-item>
              </div>
            </v-list>
          </v-menu>

          <v-btn text @click="deleteVideos()">Löschen<v-icon size="21px" color="primary" class="pl-2">mdi-delete-outline</v-icon></v-btn>
        </v-toolbar>
      </v-card>
    </v-slide-y-transition>

    <v-skeleton-loader v-if="loading" type="table-heading, table"></v-skeleton-loader>

    <v-card class="mt-4" v-else>
      <v-row class="my-1 ml-2">
        <!-- Auswahlfelder-->
        <v-col cols="3">
          <div class="d-flex">
            <div class="pt-1 pr-2">
              <v-icon color="grey" class="md-32 pt-1">mdi-magnify</v-icon>
            </div>
            <v-text-field
              v-model="search"
              class="FilterSearch"
              append-inner-icon="mdi-magnify"
              label="Suche nach Titel"
              @change="searchVal"
              dense
            ></v-text-field>
          </div>
        </v-col>

        <v-col cols="3" class="">
          <div class="d-flex ">
            <div class="pt-1 pr-2">
              <v-icon color="grey" class="md-32 pt-1">mdi-calendar-filter</v-icon>
            </div>
            <rangeDatePicker
              @input="date = $event"
              @clicked="saveFilters"
              @deleted="deleteFilters"
            ></rangeDatePicker>
          </div>
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <v-data-table
        :headers="headers"
        :items="get('videos')"
        v-model="selectedVideos"
        item-key="id"
        selectable-key="id"
        show-select
        hide-default-footer
        :items-per-page="-1"
        height="60vh"
        fixed-header
        :loading="dataTableLoading"
      >
        <template v-slot:item.title="{ item }">

          <div class="d-flex py-4">
            <v-img
              :src="imageSource(item)"
              height="70"
              width="110"
              gradient="to top right, rgba(100,115,201,.13), rgba(25,32,72,.5)"
              @click="fetchVideoModal(item, 'adminVideoPlayer')"
              class="click_cursor"
            ></v-img>

            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>{{item.title | truncate('21', '...')}}</v-list-item-title>
                <v-list-item-subtitle>{{item.description | truncate('21', '...')}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

          </div>
        </template>

        <template v-slot:item.published="{ item }">

          <div v-if="item.share">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-row @click="changeShare(item.id, false)" class="ma-0 pa-0 click_cursor" v-bind="attrs" v-on="on">

                  <v-icon color="primary">mdi-eye</v-icon>
                  <span class="pl-2">Public</span>

                </v-row>
              </template>
              <span>Das Video ist öffentlich abrufbar</span>
            </v-tooltip>
          </div>

          <div v-else>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-row @click="changeShare(item.id, true)" class="ma-0 pa-0 click_cursor" v-bind="attrs" v-on="on">
                  <v-icon color="primary">mdi-eye-off-outline</v-icon>
                  <span class="pl-2">Non Public</span>
                </v-row>
              </template>
              <span>Das Video ist nicht öffentlich sichtbar</span>
            </v-tooltip>
          </div>

          <div v-if="item.published">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-row @click="changePublish(item.id, false)" class="ma-0 pa-0 click_cursor" v-bind="attrs" v-on="on">
                  <v-icon color="primary">mdi-theater</v-icon>
                  <span class="pl-2">On Stage</span>
                </v-row>
              </template>
              <span>Das Video ist auf der Plattform sichtbar</span>
            </v-tooltip>
          </div>
          <div v-else="item.published">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-row @click="changePublish(item.id, true)" class="ma-0 pa-0 click_cursor" v-bind="attrs" v-on="on">
                  <v-icon color="primary">mdi-video-box-off</v-icon>
                  <span class="pl-2">Backstage</span>
                </v-row>
              </template>
              <span>Das Video ist nicht auf der Plattform sichtbar</span>
            </v-tooltip>
          </div>
        </template>

        <template v-slot:item.categorie="{ item }" >
          <v-list-item class="pa-0">
            <v-list-item-content>
              <v-list-item-title>{{ item }}</v-list-item-title>
              <!-- <v-list-item-subtitle>Hochgeladen</v-list-item-subtitle> -->
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-slot:item.viewer="{ item }">
          <v-list-item two-line class="pa-0" @click="linkTo( `video/${item.id}/stats`)">
            <v-list-item-content>
              <v-list-item-title>{{item.viewer}}</v-list-item-title>
              <!-- <v-list-item-subtitle>Hochgeladen</v-list-item-subtitle> -->
            </v-list-item-content>
          </v-list-item>
        </template>

        <template v-slot:item.duration="{ item }">
          <v-list-item class="pa-0" @click="linkTo( `video/${item.id}/stats`)">
            <v-list-item-content>
              <v-list-item-title>{{formDuration(item.duration_string)}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="showIcon"
                color="primary"
                @click="fetchVideoModal(item, 'adminVideoPlayer')"
                v-bind="attrs"
                v-on="on"
              >
                mdi-play-circle
              </v-icon>
            </template>
            <span>Video abspielen</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                class="ml-2 showIcon"
                @click="editVideo(item.id, 'adminVideoBearbeiten')"
                v-bind="attrs"
                v-on="on"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>Video bearbeiten</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="ml-2 showIcon"
                color="primary"
                @click="deleteVideo(item.id)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-delete
              </v-icon>

            </template>
            <span>Video löschen</span>
          </v-tooltip>

        </template>

      </v-data-table>

      <paginationToolbar
       :pagination.sync="pagination"
       @change="infiniteScroll()"
      ></paginationToolbar>

    </v-card>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import AppService from '@/services/app.service';
import APIService from '@/services/api.service';
import infiniteTableScroll from '@/services/infiniteTableScroll';
import videoPlayerService from '@/services/videoPlayer.service';

export default {
  name: 'videoDataTable',
  paginationToolbar: 'videos',

  components: {
    rangeDatePicker: () => import('@/components/datePicker/rangeDatePicker'),
    paginationToolbar: () => import('@/components/pagination/paginationToolbar'),
  },

  data(){
		return {
      showTextFilter: false,
      showDateFilter: false,
      show: false,
      playlistChoice: false,
      categoryChoice: false,
      selectedItem: [],
      selectedVideos: [],
      selectedPlaylists: [],
      selectedCategory: '',
      // selected: [],
      showOptions: false,
      loading: true,
      dataTableLoading: false,
      timeout: null,
      pagination :{
        videos: [],
        playlists: [],
        limit : 20
      },
      date: {
        startDate: '',
        endDate: '',
      },
      search: '',
      filters: {
        name: '',
        id: '',
        added_by: '',
        start_date: null,
        end_date: null,
        prop: '',
        range: '',
      },
      headers: [
        {
          text: 'Video',
          align: 'left',
          value: 'title',
          width: '80px',
        },
        {
          text: 'Kategorie',
          value: 'categorie.name',
          sortable: true,
          width: '105px',
        },

        {
          text: 'Sichtbarkeit',
          align: 'left',
          divider: false,
          value: 'published',
          width: '135px',
        },
        // {
        //   text: 'Aufrufe',
        //   value: 'viewer',
        //   sortable: true,
        //   width: '10px',
        // },
        {
          text: 'Länge',
          value: 'duration',
          sortable: true,
          width: '86px',
        },

        {
          text: 'Aktionen',
          value: 'actions',
          sortable: true,
          width: '140px',
        },
      ],
		}
	},


  computed: {
     ...mapGetters(["get"])
  },

  methods: {

    formDuration(duration) {
      return AppService.getDurationAsString(duration);
    },

    saveFilters(range) {
      this.dataTableLoading = true;
      if( range.length > 0 ){
        this.filters.range = range;
        this.infiniteScroll();
      }
      this.dataTableLoading = false;
    },

    deleteFilters() {
      this.dataTableLoading = true;
      this.filters.range = '';
      this.infiniteScroll();
      this.dataTableLoading = false;
    },

    imageSource(video){
      return AppService.imageSource(video.image);
    },


    fetchVideoModal(video, name){
      videoPlayerService.fetchVideoModal(video, name, this);
    },

    deleteVideo(id) {
      if (confirm("Möchtest du das Video wirklich löschen?")) {
        this.dataTableLoading = true;
        this.$store.dispatch('delete', { endpoint: `/api/v1/videos/${id}`, message: false})
        .then(() => {
           this.$store.dispatch("modal/close", this.name)
            this.infiniteScroll();
            APIService.setResponse({message: `Das Video wurde gelöscht`, color: "green"});
            this.dataTableLoading = false;
        })
      }
    },

    // Mehrere Videos löschen
    deleteVideos(){
      const endpoint = [];
      if (confirm("Möchtest du die Videos wirklich löschen")) {
        this.dataTableLoading = true;
        this.selectedVideos.forEach(
          video => {
            endpoint.push(`/api/v1/videos/${video.id}`)
          }
        )
        this.$store.dispatch('delete',
          {
            endpoint: endpoint,
            message: false
          })
          .then(() => {
            APIService.setResponse({ message: `Es wurden ${endpoint.length} Videos gelöscht`, color: "green" });
            this.infiniteScroll();
            this.dataTableLoading = false;
            this.selectedPlaylists = [];
          })
      }
    },

    addVideosToPlaylists() {
      this.dataTableLoading = true;
      const endpoint = [];
      if(this.selectedPlaylists.length > 0){
        const videos = this.selectedVideos.map(video => {
            return video.id
        })

        this.selectedPlaylists.forEach(
          id => {
            endpoint.push(`/api/v1/playlists/${id}`)
          }
        )
        this.$store.dispatch('patch',
        {
          endpoint: endpoint,
          event: { videos: videos },
          message: false
        }).then(() => {
          this.infiniteScroll();
          APIService.setResponse({ message: `Es wurden ${videos.length} Videos der Playlist hinzugefügt`, color: "green"})
          this.selectedVideos = [];
          this.playlistChoice = false;
          this.dataTableLoading = false;
        })
      } else {
        APIService.setResponse({ message: `Es wurden keine Videos ausgewählt`, color: "red"})
        this.playlistChoice = false;
        this.dataTableLoading = false;
      }
    },

    addVideosToCategory(){

      this.dataTableLoading = true;

      const endpoint = [];
      this.selectedVideos.forEach(
        item => {
          endpoint.push(`/api/v1/videos/${item.id}`)
        }
      )
      this.$store.dispatch('patch',
      {
        endpoint: endpoint,
        event: { categorie: this.selectedCategory },
        message: false
      }).then(() => {
        this.infiniteScroll();
        APIService.setResponse({ message: `Es wurden ${endpoint.length} Videos der Kategorie hinzugefügt`, color: "green"})
        this.selectedVideos = [];
        this.categoryChoice = false;
        this.dataTableLoading = false;
      }).catch(() => {
        this.dataTableLoading = false;
        this.categoryChoice = false;
        APIService.setResponse({message: 'Bitte mindestens eine Kategorie auswählen', color: "red"});
      })
    },

    changeShareSelected(share){

      this.dataTableLoading = true;

      const endpoint = [];
      this.selectedVideos.forEach(
        item => {
          endpoint.push(`/api/v1/videos/${item.id}`)
        }
      )
      this.$store.dispatch('patch',
      {
        endpoint: endpoint,
        event: { share: share },
        message: false
      }).then(() => {
        this.infiniteScroll();
        APIService.setResponse({
          message: share ? `Es wurden ${endpoint.length} Videos auf der Plattform sichtbar gemacht.` : `${endpoint.length} Videos sind nicht mehr auf der Plattform sichtbar`,
          color: share ? "green" : "blue",
          time: 1500
        })
        this.dataTableLoading = false;
      })
    },

    changeShare(id, share) {
      this.dataTableLoading = true;
      this.$store.dispatch('patch',
        {
          endpoint: `/api/v1/videos/${id}`,
          event: { share: share },
          message: false
        }
      ).then(() => {
        this.$store.dispatch('fetch', { endpoint: '/api/v1/videos?sort=DESC', state: 'videos'});
        APIService.setResponse({
          message: share ? 'Das ist Video ist nun öffentlich verfügbar' : 'Das Video ist nicht mehr öffentlich',
          color: share ? "green" : "blue"
        })
        this.dataTableLoading = false;
      })
      // console.log("Ändere State von Published")
    },

    changePublishSelected() {

      this.dataTableLoading = true;
      var pubStatus;
      const endpoint = [];
      this.selectedVideos.forEach(
        item => {
          if (item.published) {
            pubStatus = false;
            console.log("pubStatus soll false werden", pubStatus, item.published)
          } else {
            pubStatus = true;
            console.log("pubStatus soll true werden", pubStatus, item.published)
          }
          endpoint.push(`/api/v1/videos/${item.id}`)
        }
      )
      this.$store.dispatch('patch',
      {
        endpoint: endpoint,
        event: { published: pubStatus },
        message: false
      }).then(() => {
        this.infiniteScroll();
        this.selectedVideos = [];
        APIService.setResponse({ message: `Es wurden ${endpoint.length} Videos auf der Plattform sichtbar gemacht.`, color: "green", time: 1500 })
        this.dataTableLoading = false;
      })
    },


    changePublish(id, published) {
      this.dataTableLoading = true;
      this.$store.dispatch('patch',
        {
          endpoint: `/api/v1/videos/${id}`,
          event: { published: published },
          message: false
        }
      ).then(() => {
        this.$store.dispatch('fetch', { endpoint: '/api/v1/videos?sort=DESC', state: 'videos'});
        APIService.setResponse({
          message: published ? 'Das Video ist nun auf der Plattform verfügbar' : 'Das Video ist nicht mehr auf der Plattform sichtbar',
          color: "green"
        })
        this.dataTableLoading = false;
      })
    },

    openModal(name) {
        this.$store.dispatch("modal/open", name)
    },

    closeModal() {
        this.$store.dispatch("modal/close", this.name)
    },

    editVideo(id, name){
      this.$store.dispatch('fetch', { endpoint: `/api/v1/videos/${id}`, state: 'video'})
      this.$store.dispatch("modal/open", name)
    },

    linkTo(route){
      this.$router.push('/'+ route)
    },

    formDate(value){
     if (value) {
       return moment(String(value)).format('DD.MM.YYYY')
      }
    },

    infiniteScroll(endpoint = `/api/v1/videos?limit=${this.pagination.limit}&${this.filters.range}&sort=DESC`, state = 'videos'){
      return infiniteTableScroll.init(this, endpoint, state)
    },

    searchVal(search){
      this.$store.dispatch('fetch', { endpoint: `/api/v1/videos?search=${search}`, state: 'videos'})
    }

  },

  mounted(){
    this.infiniteScroll().then((infiniteTableScroll) => {
      this.loading = false
      infiniteTableScroll.addEventListener('.v-data-table__wrapper')
    })
  },
}

</script>
