const rightSiteDrawer = {
	namespaced: true,
	state: {
    	drawer: false
	},
	getters: {
   	 	getDrawer: state => state.drawer,
	},
	mutations: {
    // toogle: state => state.drawer = !state.drawer,
		toogle(state, val) {
			state.drawer = val
		}
	},
	actions: {
		toogle: ( {commit} ) => commit('toogle'),
	}
}

export default rightSiteDrawer
